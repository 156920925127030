<template>
  <!-- <v-app> -->
  <!-- <v-container fluid> -->
  <v-row>
    <v-col cols="12">
      <!-- :show-expand="expanded !== ''" -->
      <v-data-table
        :headers="headersinmuebles"
        :items="inmuebles"
        :search="buscador"
        item-key="pkinmueble"
        class="elevation-1 bordecolor"
        :expanded.sync="expanded"
        locale="es"
      >
        <template v-slot:top>
          <v-toolbar flat color="claro" dark>
            <v-toolbar-title>INMUEBLES</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="buscador"
              append-icon="mdi-magnify"
              label="Buscar"
              filled
              dense
              outlined
              single-line
              hide-details
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-btn
              color="white"
              style="margin-top: 0px; margin-left: 15px"
              @click="DialogNuevoInmueble = true"
            >
              <h4 style="color: #000">Nuevo Inmueble</h4>
            </v-btn>
          </v-toolbar>
        </template>
        <template v-slot:[`item.estado`]="{ item }">
          <!-- <v-chip
                :class="
                 laclase(item).class
                "
                outlined
                label
              >
                {{item.condiDeta}}
              </v-chip> -->
          <span :class="laclase(item).class">{{ item.condiDeta }} </span>
        </template>
        <template v-slot:[`item.editar`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                style="
                  background-color: #000;
                  width: 120%;
                  height: 60%;
                  border-radius: 5px;
                "
                v-bind="attrs"
                v-on="on"
                small
                :disabled="expandedIsActive"
                dark
                @click="AbrirEditarInmueble(item)"
              >
                mdi-pencil
              </v-icon>
            </template>
            <span>Editar</span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.informacion`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                style="
                  background-color: #000;
                  width: 120%;
                  height: 60%;
                  border-radius: 5px;
                "
                v-bind="attrs"
                v-on="on"
                :disabled="expandedIsActive"
                dark
                @click="(datosinmueble = item), (dialoginformacion = true)"
              >
                mdi-information-variant
              </v-icon>
            </template>
            <span>Información</span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                style="
                  background-color: #000;
                  border-radius: 5px;
                  height: 33px;
                  width: 35px;
                "
                v-bind="attrs"
                v-on="on"
                dark
                :disabled="expandedIsActive"
                small
                @click="AbrirDialogDeudas(item)"
              >
                mdi-currency-usd
              </v-icon>
            </template>
            <span>Pagar deudas</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                style="
                  background-color: #000;
                  border-radius: 5px;
                  height: 33px;
                  width: 35px;
                  margin-left: 17px;
                "
                v-bind="attrs"
                v-on="on"
                dark
                :disabled="expandedIsActive"
                small
                @click="AbrirDialogPlanDePagos(item)"
              >
                mdi-cash-multiple
              </v-icon>
            </template>
            <span>Plan de Pagos</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                style="
                  margin-left: 17px;
                  background-color: #000;
                  border-radius: 5px;
                  height: 33px;
                  width: 35px;
                "
                v-bind="attrs"
                v-on="on"
                :disabled="expandedIsActive"
                dark
                small
                @click="AbrirDialogAdhesionDebito()"
              >
                mdi-credit-card-outline
              </v-icon>
            </template>
            <span>Adhesion Débito Automático</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                style="
                  margin-left: 17px;
                  background-color: #000;
                  border-radius: 5px;
                  height: 33px;
                  width: 35px;
                "
                v-bind="attrs"
                v-on="on"
                :disabled="expandedIsActive"
                dark
                small
                @click="CodigoPago(item)"
              >
                mdi-barcode
              </v-icon>
            </template>
            <span>Código Pago Electrónico</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                style="
                  margin-left: 17px;
                  background-color: #000;
                  border-radius: 5px;
                  height: 33px;
                  width: 35px;
                "
                v-bind="attrs"
                v-on="on"
                :disabled="expandedIsActive"
                dark
                small
                @click="AbrirDialogAdhesionBoleta(item)"
              >
                mdi-sticker-text-outline
              </v-icon>
            </template>
            <span>Adhesion Boleta Electrónica</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                style="
                  margin-left: 17px;
                  background-color: red;
                  border-radius: 5px;
                  height: 33px;
                  width: 35px;
                "
                v-bind="attrs"
                :disabled="expandedIsActive"
                dark
                small
                v-on="on"
                @click="EliminarInmueble(item)"
              >
                mdi-delete
              </v-icon>
            </template>
            <span>Eliminar Inmueble</span>
          </v-tooltip>
        </template>

        <!-- <template v-slot:[`expanded-item`]="{ headers, item }">
          <td :colspan="headers.length" style="padding: 0px !important">
            <v-container fluid>
              <v-row no-gutters justify="center">
                <v-card
                  min-width="500"
                  v-if="showDetails == 'editinmu'"
                  class="bordecolor"
                >
                  <v-card-title class="headline">
                    <v-row justify="center" style="margin-top: 5px">
                      <v-icon
                        style="
                          background-color: #bdbdbd;
                          width: 30px;
                          height: 30px;
                          border-radius: 5px;
                          margin-right: 10px;
                        "
                        dark
                      >
                        mdi-pencil
                      </v-icon>
                      <b>Editar Inmueble</b>
                    </v-row>
                  </v-card-title>
                  <v-card-text>
                    <v-text-field
                      v-model="editardescripcion"
                      placeholder="*Escriba aqui"
                      outlined
                      dense
                      filled
                      label="Descripción"
                      :rules="[(v) => !!v || 'Falta la descripcion']"
                      required
                      class="mt-6"
                    />
                  </v-card-text>
                  <v-card-actions class="justify-center">
                    <v-btn @click="expanded = []" class="mr-2" color="error">
                      cancelar
                    </v-btn>
                    <v-btn dark color="claro" @click="EditarInmueble()">
                      Confirmar
                    </v-btn>
                  </v-card-actions>
                </v-card>
                <v-card
                  width="500"
                  v-if="showDetails == 'info'"
                  class="bordecolor"
                >
                  <v-card-title class="headline">
                    <v-row justify="center" style="margin-top: 5px">
                      <v-icon
                        style="
                          background-color: #bdbdbd;
                          width: 30px;
                          height: 30px;
                          border-radius: 5px;
                          margin-right: 10px;
                        "
                        dark
                        >http://localhost:8080/escribanos
                        mdi-information-variant
                      </v-icon>
                      <b>Información del Inmueble </b>
                    </v-row>
                  </v-card-title>
                  <v-card-text>
                    <v-row class="mt-4">
                      <v-col md="6" sm="12">
                        <p class="textosecundario"><b>Cuenta Municipal:</b></p>
                        <p class="textoprincipal">
                          <b>{{ item.cuenta }}/{{ item.d_vefi }}</b>
                        </p>
                        <p class="textosecundario"><b>Categoría:</b></p>
                        <p class="textoprincipal">
                          <b> {{ item.categoria }}</b>
                        </p>
                        <p class="textosecundario">
                          <b>Código de Servicio:</b>
                        </p>
                        <p class="textoprincipal">
                          <b> {{ item.destino }}</b>
                        </p>
                      </v-col>
                      <v-col md="6" sm="12">
                        <p class="textosecundario">
                          <b>Partida Provincial:</b>
                        </p>
                        <p class="textoprincipal">
                          <b> {{ item.partida }}</b>
                        </p>
                        <p class="textosecundario"><b>Destino:</b></p>
                        <p class="textoprincipal">
                          <b> {{ item.destino }}</b>
                        </p>
                        <p class="textosecundario"><b>Base Imponible:</b></p>
                        <p class="textoprincipal">
                          <b> {{ item.base_imponible }}</b>
                        </p>
                      </v-col>
                      <v-col md="12" sm="12" style="margin-top: -25px">
                        <p class="textosecundario">
                          <b>Nomenclatura Catastral:</b>
                        </p>
                        <p class="textoprincipal">
                          <b> {{ item.nomenclatura }}</b>
                        </p>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions class="d-flex justify-center">
                    <v-btn color="error" @click="expanded = []">
                      cancelar
                    </v-btn>
                  </v-card-actions>
                </v-card>
                <v-card
                  width="500"
                  v-if="showDetails == 'codpele'"
                  class="bordecolor"
                >
                  <v-card-title class="headline">
                    <v-row justify="center" style="margin-top: 5px">
                      <b>Código de Pago Electrónico</b>
                    </v-row>
                  </v-card-title>
                  <v-card-text>
                    <v-text-field
                      v-model="codigoelectronico"
                      placeholder="0"
                      class="mt-6"
                      dense
                      filled
                      outlined
                      readonly
                    ></v-text-field>
                  </v-card-text>
                  <v-card-actions class="justify-center">
                    <v-btn class="mr-2" color="error" @click="expanded = []">
                      cancelar
                    </v-btn>
                    <v-btn
                      color="claro"
                      dark
                      style="width: 100px"
                      @click="CopiarTexto()"
                    >
                      Copiar
                    </v-btn>
                  </v-card-actions>
                </v-card>
                <v-card v-if="showDetails == 'debito'" flat width="900">
                  <adhesiondebito
                    ref="adhesiondebitoform"
                    @vaciarExpanded="vaciarExpanded"
                    :user="componentepagardeuda"
                  ></adhesiondebito>
                </v-card>
                <v-card
                  width="500"
                  v-if="showDetails == 'bolelec'"
                  class="bordecolor"
                >
                  <adhesionboleta
                    ref="adhesionboletaform"
                    @vaciarExpanded="vaciarExpanded"
                    :user="componentepagardeuda"
                  ></adhesionboleta>
                </v-card>
                <v-card class="ancho_tabla" v-if="showDetails=='pagarplandepagos'">
                  <pagarplandepagos ref="pagarplandepagosform" @vaciarExpanded="vaciarExpanded" :user="componentepagardeuda"></pagarplandepagos>
                </v-card>
                <v-card v-if="showDetails == 'pagardeudas'" class="ancho_tabla">
                  <pagardeudas
                    ref="formDeuda"
                    @vaciarExpanded="vaciarExpanded"
                    :user="componentepagardeuda"
                  ></pagardeudas>
                </v-card>
              </v-row>
            </v-container>
          </td>
        </template> -->
        <template v-slot:no-data>
          <v-btn color="claro" dark @click="initialize">Cargar</v-btn>
        </template>
      </v-data-table>

      <v-dialog v-model="DialogNuevoInmueble" width="500" persistent>
        <v-card class="bordecolor">
          <v-card-title class="font-weight-bold">
            <v-row justify="center" style="margin-top: 5px">
              Agregar Nuevo Inmueble
            </v-row>
          </v-card-title>
          <v-card-text class="mt-4">
            <v-form
              ref="formnuevoinmueble"
              v-model="validformnuevoinmueble"
              lazy-validation
            >
              <p style="margin-bottom: 5px" class="font-weight-medium">
                Cuenta Municipal
              </p>
              <v-row>
                <v-col md="6">
                  <v-text-field
                    v-model="cuentamunicipal1"
                    type="number"
                    placeholder="0"
                    :rules="[(v) => !!v || '']"
                    required
                    dense
                    outlined
                    filled
                  ></v-text-field>
                </v-col>
                <v-col md="1">
                  <h1 style="margin-top: 5px">/</h1>
                </v-col>
                <v-col md="5">
                  <v-text-field
                    v-model="cuentamunicipal2"
                    placeholder="0"
                    type="number"
                    :rules="[(v) => !!v || '']"
                    required
                    dense
                    outlined
                    filled
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row style="margin-top: -35px">
                <v-col cols="12">
                  <v-text-field
                    v-model="partida"
                    label="Partida"
                    :rules="[(v) => !!v || '']"
                    required
                    dense
                    outlined
                    filled
                  ></v-text-field>
                </v-col>
                <v-col cols="12" style="margin-top: -35px">
                  <v-text-field
                    v-model="descripcion"
                    label="Descripcion"
                    placeholder="Nombre de referencia"
                    :rules="[(v) => !!v || '']"
                    required
                    dense
                    outlined
                    filled
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row justify="center" style="margin-top: -30px">
                <v-checkbox v-model="checkboxtitular">
                  <template v-slot:label>
                    <medium> Declaro ser titular / apoderado. </medium>
                  </template>
                </v-checkbox>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions class="justify-center">
            <v-btn color="error" @click="DialogNuevoInmueble = false">
              cerrar
            </v-btn>
            <v-btn color="claro" dark @click="NuevoInmueble()"> Guardar </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="dialoginformacion"
        persistent
        max-width="500"
        class="zindex-dialog"
      >
        <v-card>
          <v-card-title class="headline">
            <v-row justify="center" style="margin-top: 5px">
              <v-icon
                style="
                  background-color: #bdbdbd;
                  width: 30px;
                  height: 30px;
                  border-radius: 5px;
                  margin-right: 10px;
                "
                dark
              >
                mdi-information-variant
              </v-icon>
              <b>Información del Inmueble </b>
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-row class="mt-4">
              <v-col md="6" sm="12">
                <p class="textosecundario"><b>Cuenta Municipal:</b></p>
                <p class="textoprincipal">
                  <b>{{ datosinmueble.cuenta }}/{{ datosinmueble.d_vefi }}</b>
                </p>
                <p class="textosecundario"><b>Categoría:</b></p>
                <p class="textoprincipal">
                  <b> {{ datosinmueble.categoria }}</b>
                </p>
                <p class="textosecundario">
                  <b>Código de Servicio:</b>
                </p>
                <p class="textoprincipal">
                  <b> {{ datosinmueble.destino }}</b>
                </p>
              </v-col>
              <v-col md="6" sm="12">
                <p class="textosecundario">
                  <b>Partida Provincial:</b>
                </p>
                <p class="textoprincipal">
                  <b> {{ datosinmueble.partida }}</b>
                </p>
                <p class="textosecundario"><b>Destino:</b></p>
                <p class="textoprincipal">
                  <b> {{ datosinmueble.destino }}</b>
                </p>
                <p class="textosecundario"><b>Base Imponible:</b></p>
                <p class="textoprincipal">
                  <b> {{ datosinmueble.base_imponible }}</b>
                </p>
              </v-col>
              <v-col md="12" sm="12" style="margin-top: -25px">
                <p class="textosecundario">
                  <b>Nomenclatura Catastral:</b>
                </p>
                <p class="textoprincipal">
                  <b> {{ datosinmueble.nomenclatura }}</b>
                </p>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="d-flex justify-center">
            <v-btn color="error" @click="dialoginformacion = false">
              cancelar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="dialogeditar"
        persistent
        max-width="500"
        class="zindex-dialog"
      >
        <v-card>
          <v-card-title class="headline">
            <v-row justify="center" style="margin-top: 5px">
              <v-icon
                style="
                  background-color: #bdbdbd;
                  width: 30px;
                  height: 30px;
                  border-radius: 5px;
                  margin-right: 10px;
                "
                dark
              >
                mdi-pencil
              </v-icon>
              <b>Editar Inmueble</b>
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-text-field
              v-model="editardescripcion"
              placeholder="*Escriba aqui"
              outlined
              dense
              filled
              label="Descripción"
              :rules="[(v) => !!v || 'Falta la descripcion']"
              required
              class="mt-6"
            />
          </v-card-text>
          <v-card-actions class="justify-center">
            <v-btn @click="dialogeditar = false" class="mr-2" color="error">
              cancelar
            </v-btn>
            <v-btn dark color="claro" @click="EditarInmueble()">
              Confirmar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="dialogcodigo"
        persistent
        max-width="500"
        class="zindex-dialog"
      >
        <v-card>
          <!-- <v-app-bar color="oscuro" fixed dark>
          <v-toolbar-title>Pagar deudas de Pepito</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog = false"><v-icon>mdi-close</v-icon></v-btn>
        </v-app-bar> -->
          <v-card-title class="headline">
            <v-row justify="center" style="margin-top: 5px">
              <b>Código de Pago Electrónico</b>
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-text-field
              v-model="codigoelectronico"
              placeholder="0"
              class="mt-8"
              filled
              outlined
              readonly
            ></v-text-field>
          </v-card-text>
          <v-card-actions class="justify-center">
            <v-btn class="mr-2" color="error" @click="CerrarCodigo()">
              cancelar
            </v-btn>
            <v-btn
              color="claro"
              dark
              style="width: 100px"
              @click="CopiarTexto()"
            >
              Copiar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <pagardeudas
        ref="formDeuda"
        @vaciarExpanded="vaciarExpanded"
        :user="componentepagardeuda"
      ></pagardeudas>
      <adhesionboleta
        ref="adhesionboletaform"
        @vaciarExpanded="vaciarExpanded"
        :user="componentepagardeuda"
      ></adhesionboleta>
      <adhesiondebito
        ref="adhesiondebitoform"
        @vaciarExpanded="vaciarExpanded"
        @ActualizarTabla="ListadoInmueble"
        :user="componentepagardeuda"
      ></adhesiondebito>
      <pagarplandepagos
        ref="pagarplandepagosform"
        @vaciarExpanded="vaciarExpanded"
        :user="componentepagardeuda"
      ></pagarplandepagos>
    </v-col>
  </v-row>

  <!-- </v-container> -->
</template>

<script>
import pagardeudas from "../Inmuebles/Pagar_deuda_inmueble";
import pagarplandepagos from "../Inmuebles/Pagar_plandepagos_inmueble";
import adhesionboleta from "../Inmuebles/Adhesion_boleta_electronica_inmueble";
import adhesiondebito from "../Inmuebles/Adhesion_debito_automatico_inmueble";
export default {
  data: () => ({
    dialogcodigo: false,
    dialogeditar: false,
    dialoginformacion: false,
    timeout: 3000,
    msj: "",
    expanded: [],
    headersinmuebles: [
      { text: "DESCRIPCION", align: "start", value: "descripcion" },
      { align: "start", value: "editar", width: 55 },
      { text: "CUENTA MUNICIPAL", value: "cuentaYvefi" },
      { align: "start", value: "informacion", width: 55 },
      { text: "PARTIDA PROVINCIAL", value: "partida" },
      { text: "ESTADO", value: "estado" },
      {
        text: "OPCIONES",
        value: "actions",
        align: "right",
        width: 350,
        sortable: false,
      },
    ],
    inmuebles: [],
    buscador: "",
    inmueble: "",
    showDetails: "",
    dialogConsultas: false,
    datevencimiento: new Date().toISOString().substr(0, 10),
    datevencimiento2: new Date().toISOString().substr(0, 10),
    menu: false,
    menu2: false,
    DialogNuevoInmueble: false,
    cuentamunicipal1: "",
    cuentamunicipal2: "",
    partida: "",
    descripcion: "",
    checkboxtitular: false,
    validformnuevoinmueble: true,
    pkinmueble: "",

    editardescripcion: "",

    pagomensual: "1",
    pagardeuda: false,
    plandepagos: false,
    codigopagoelectronico: "",

    datosinmueble: {},
    opcion: 0,
    codigoelectronico: "",
  }),

  components: {
    pagardeudas,
    pagarplandepagos,
    adhesionboleta,
    adhesiondebito,
  },

  computed: {
    componentepagardeuda() {
      return {
        vencimiento: this.datevencimiento,
        vencimiento2: this.datevencimiento2,
        pkinmueble: this.pkinmueble,
        datosinmueble: this.datosinmueble,
      };
    },
    codigo() {
      if (this.datosinmueble) {
        return `089${this.datosinmueble.cuenta.toString().padStart(7, "0")}${
          this.datosinmueble.d_vefi
        }`;
      } else {
        return "";
      }
    },
    expandedIsActive() {
      return this.expanded.length > 0;
    },
  },

  watch: {},

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      this.ListadoInmueble();
    },

    CodigoPago(item) {
      console.log("entra");
      this.codigoelectronico = `089${item.cuenta.toString().padStart(7, "0")}${
        item.d_vefi
      }`;
      this.$nextTick(() => {
        this.dialogcodigo = true;
      });
      // (showDetails = 'codpele'),
      // (expanded = [item]),
    },

    CerrarCodigo() {
      this.codigoelectronico = "";
      this.dialogcodigo = false;
    },

    CopiarTexto() {
      navigator.clipboard.writeText(this.codigoelectronico);
      this.$swal.fire({
        title: "¡Exito!",
        text: "Código copiado con éxito",
        icon: "success",
      });
    },

    ListadoInmueble() {
      console.log("entra");
      var obj = this;
      this.$ajax
        .get(`${this.$apitramites}inmuebles/traerInmuebles`)
        .then(function (response) {
          console.log("Listado Inmuebles", response);
          obj.inmuebles = response.data;
        })
        .catch(function (error) {
          console.log(error);
          // obj.$swal.fire({
          //   title: "Error",
          //    text: "Sin datos cargados",
          //    icon: "error"
          //  });
        });
    },

    // EliminarInmueble(){
    //   var obj = this;
    //     this.$ajax
    //     .delete(`${this.$apitramites}inmuebles/${this.pkinmueble}`)
    //     .then(function(response) {
    //       console.log("Eliminar inmueble", response);
    //       obj.msj= "Inmueble eliminado con exito!!!";
    //       obj.snackbarconfirm=true;
    //       obj.ListadoInmueble();
    //       obj.DialogEliminarInmueble=false;
    //     })
    //     .catch(function(error) {
    //       obj.snackbarconfirm=true;
    //       obj.msj= error;
    //     });
    // },

    AbrirEditarInmueble(item) {
      // (showDetails = 'editinmu'), (expanded = [item]);
      this.editardescripcion = item.descripcion;
      this.pkinmueble = item.pkinmueble;
      this.dialogeditar = true;
    },

    EditarInmueble() {
      var obj = this;
      this.$ajax
        .patch(`${this.$apitramites}Inmuebles/${this.pkinmueble}`, {
          descripcion: this.editardescripcion,
        })
        .then(function (response) {
          console.log("Editar inmueble", response);
          obj.$swal.fire({
            title: "¡Editado!",
            text: "Inmueble editado con éxito",
            icon: "success",
          });
          obj.ListadoInmueble();
          obj.expanded = [];
        })
        .catch(function (error) {
          obj.$swal.fire({
            title: "Error",
            text: error,
            icon: "error",
          });
        });
    },

    // laclase(item){
    //   if(item.condicontri != '2'){
    //     if(item.t_desvie == '1' && item.t_lugar == '1'){
    //       return {class:"green--text" , restringido: false};
    //     }else if( ((item.t_desvie == '1' && item.t_lugar =='0') || (item.t_desvie =='0' && item.t_lugar =='1')) && item.t_web == '1'){
    //       return {class:"orange--text" , restringido: false};
    //     }else{
    //       return {class:"red--text" , restringido: true};
    //     }
    //   }else{
    //       return {class:"red--text" , restringido: true};
    //   }
    // },

    laclase(item) {
      if (item.condicontri != "2") {
        if (item.condiDeta == "Normal") {
          return { class: "green--text", restringido: false };
        } else if (item.condiDeta == "Baja") {
          return { class: "orange--text", restringido: false };
        } else {
          return { class: "red--text", restringido: true };
        }
      } else {
        return { class: "red--text", restringido: true };
      }
    },

    NuevoInmueble() {
      if (this.$refs.formnuevoinmueble.validate()) {
        // alert("puto");
        var obj = this;
        this.$ajax
          .post(`${this.$apitramites}inmuebles`, {
            cuenta: Number(this.cuentamunicipal1),
            d_vefi: Number(this.cuentamunicipal2),
            partida: Number(this.partida),
            descripcion: this.descripcion,
            titular: this.checkboxtitular,
          })
          .then(function (response) {
            console.log("Nuevo inmueble", response);
            if (response) {
              obj.$swal.fire({
                title: "¡Agregado!",
                text: "Inmueble cargado con éxito",
                icon: "success",
              });
              obj.ListadoInmueble();
              obj.DialogNuevoInmueble = false;
              obj.$refs.formnuevoinmueble.reset();
            } else {
              // obj.msj= "Error al cargar inmueble";
              // obj.alerta= true;
              obj.$swal.fire({
                title: "Error",
                text: "Hubo un error al agregar el inmueble",
                icon: "error",
              });
            }
          })
          .catch(function (error) {
            let mensaje = "Error, intente nuevamente";
            switch (error.response.data.message) {
              case "NOT_EXIST":
                mensaje = "No se encuentra el inmueble";
                break;
              case "ROW_EXIST":
                mensaje = "El inmueble ya está asociado a su cuenta";
                break;
            }
            obj.$swal.fire({
              title: "Error",
              text: mensaje,
              icon: "error",
            });
          });
      }
    },

    AbrirDialogDeudas(item) {
      this.datosinmueble = item;
      this.pkinmueble = item.pkinmueble;

      const checkAndCallListado = () => {
        if (this.$refs.formDeuda) {
          this.$refs.formDeuda.ListadoCuotas(item);
        } else {
          setTimeout(checkAndCallListado, 100); // Reintenta después de 100ms
        }
      };

      this.$nextTick(checkAndCallListado);
    },

    AbrirDialogPlanDePagos(item) {
      // (showDetails = 'pagarplandepagos'),
      //               (expanded = [item]),
      this.datosinmueble = item;
      this.pkinmueble = item.pkinmueble;
      this.$nextTick(() => {
        this.$refs["pagarplandepagosform"].ListadoCuotas(item);
      });
    },

    AbrirDialogAdhesionBoleta(item) {
      // (showDetails = 'bolelec'),
      //               (expanded = [item]),
      this.datosinmueble = item;
      this.$nextTick(() => {
        this.$refs["adhesionboletaform"].initialize();
      });
    },

    AbrirDialogAdhesionDebito(item) {
      //     (showDetails = 'debito'),
      // (expanded = [item]),

      this.datosinmueble = item;
      this.$nextTick(() => {
        this.$refs["adhesiondebitoform"].LlenarBancos();
      });
    },

    vaciarExpanded() {
      this.expanded = [];
    },

    EliminarInmueble(item) {
      this.pkinmueble = item.pkinmueble;
      console.log(item);
      var obj = this;
      this.$swal
        .fire({
          title: "¿Desea eliminar este inmueble?",
          text: "¡Esta acción no se puede revertir!",
          icon: "warning",
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: "No, cancelar!",
          confirmButtonText: "Sí, eliminar!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            obj.$ajax
              .delete(`${obj.$apitramites}inmuebles/${obj.pkinmueble}`)
              .then(function (response) {
                console.log("Eliminar inmueble", response);
                obj.$swal.fire({
                  title: "¡Eliminado!",
                  text: "Inmueble eliminado con éxito",
                  icon: "success",
                });
                obj.ListadoInmueble();
              })
              .catch(function (error) {
                console.error("Error al eliminar inmueble", error);
                obj.$swal.fire({
                  title: "Error",
                  text: "Hubo un error al eliminar el inmueble",
                  icon: "error",
                });
              });
          }
        });
    },
  },
};
</script>

<style>
.margeninputs {
  margin-top: -60px;
}
.textoprincipal {
  font-size: large;
  margin-bottom: 25px !important;
}
.textosecundario {
  color: #4f798f;
  margin-bottom: 0px !important;
  font-size: medium;
}
</style>
<style scoped>
.label-text {
  margin-bottom: 4px;
  font-weight: bold;
  font-size: 15px;
  opacity: 0.85;
}
.v-text-field--outlined fieldset {
  height: 40px;
  border: 2px solid #40a5dd !important;
}
.v-text-field__slot {
  height: 38px;
}
</style>
