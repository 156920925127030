<template>
  <!-- <v-app style="width:100%;"> -->
  <!-- <v-row> -->
  <v-col cols="12" style="padding: 30px">
    <v-card
      style="border: 1px solid; margin-bottom: 20px"
      class="mt-10"
      v-if="datosprofesional.estado == 0"
    >
      <v-form
        @submit.prevent="EnviarSolicitud"
        ref="formsolicitudobra"
        v-model="validformsolicitudobra"
      >
        <!-- {{datosprofesional}} -->
        <v-toolbar dark color="claro" flat>
          <v-toolbar-title
            >Solicitud de registro de profesionales</v-toolbar-title
          >
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="12" class="mt-4">
              <v-row>
                <v-col md="3" sm="6">
                  <v-text-field
                    name="cuit"
                    label="CUIT"
                    v-model="datosprofesional.user[0].cuit"
                    readonly
                    placeholder=""
                    :rules="[(v) => !!v || 'requerido']"
                    required
                    filled
                    outlined
                    dense
                  />
                </v-col>
                <v-col md="3" sm="6">
                  <v-text-field
                    name="apellido"
                    label="APELLIDO"
                    :value="
                      datosprofesional.form.length != 0
                        ? datosprofesional.form[0].apellido
                        : ''
                    "
                    placeholder=""
                    :rules="[(v) => !!v || 'requerido']"
                    required
                    filled
                    outlined
                    dense
                  />
                </v-col>
                <v-col md="3" sm="6">
                  <v-text-field
                    name="nombre"
                    :value="
                      datosprofesional.form.length != 0
                        ? datosprofesional.form[0].nombre
                        : ''
                    "
                    label="NOMBRE"
                    placeholder=""
                    :rules="[(v) => !!v || 'requerido']"
                    required
                    filled
                    outlined
                    dense
                  />
                </v-col>
                <v-col md="3" sm="6">
                  <v-text-field
                    name="provincia"
                    label="PROVINCIA"
                    :value="
                      datosprofesional.form.length != 0
                        ? datosprofesional.form[0].provincia
                        : ''
                    "
                    placeholder=""
                    :rules="[(v) => !!v || 'requerido']"
                    required
                    filled
                    outlined
                    dense
                  />
                </v-col>
                <v-col cols="5">
                  <v-row>
                    <v-col md="9" sm="6">
                      <v-text-field
                        name="localidad"
                        label="LOCALIDAD"
                        :value="
                          datosprofesional.form.length != 0
                            ? datosprofesional.form[0].localidad
                            : ''
                        "
                        placeholder=""
                        :rules="[(v) => !!v || 'requerido']"
                        required
                        filled
                        outlined
                        dense
                        class="margen"
                      />
                    </v-col>
                    <v-col md="3" sm="6">
                      <v-text-field
                        name="cod_postal"
                        label="C.P"
                        :value="
                          datosprofesional.form.length != 0
                            ? datosprofesional.form[0].codigo_postal
                            : ''
                        "
                        placeholder=""
                        :rules="[(v) => !!v || 'requerido']"
                        required
                        filled
                        outlined
                        dense
                        class="margen"
                      />
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="7">
                  <v-row>
                    <v-col md="6" sm="6">
                      <v-text-field
                        name="calle"
                        label="CALLE"
                        :value="
                          datosprofesional.form.length != 0
                            ? datosprofesional.form[0].calle
                            : ''
                        "
                        placeholder=""
                        :rules="[(v) => !!v || 'requerido']"
                        required
                        filled
                        outlined
                        dense
                        class="margen"
                      />
                    </v-col>
                    <v-col md="2" sm="6">
                      <v-text-field
                        name="num_calle"
                        label="N°"
                        :value="
                          datosprofesional.form.length != 0
                            ? datosprofesional.form[0].num_calle
                            : ''
                        "
                        placeholder=""
                        filled
                        outlined
                        dense
                        class="margen"
                      />
                    </v-col>
                    <v-col md="2" sm="6">
                      <v-text-field
                        name="piso"
                        label="PISO"
                        :value="
                          datosprofesional.form.length != 0
                            ? datosprofesional.form[0].piso
                            : ''
                        "
                        placeholder=""
                        filled
                        outlined
                        dense
                        class="margen"
                      />
                    </v-col>
                    <v-col md="2" sm="6">
                      <v-text-field
                        name="depto"
                        label="DEPTO"
                        :value="
                          datosprofesional.form.length != 0
                            ? datosprofesional.form[0].depto
                            : ''
                        "
                        placeholder=""
                        filled
                        outlined
                        dense
                        class="margen"
                      />
                    </v-col>
                  </v-row>
                </v-col>
                <v-col md="3" sm="6">
                  <v-text-field
                    name="mail"
                    label="MAIL"
                    v-model="datosprofesional.user[0].mail"
                    readonly
                    placeholder=""
                    :rules="[(v) => !!v || 'requerido']"
                    required
                    filled
                    outlined
                    dense
                    class="margen"
                  />
                </v-col>
                <v-col md="3" sm="6">
                  <v-text-field
                    name="telefono"
                    label="TELÉFONO"
                    :value="
                      datosprofesional.form.length != 0
                        ? datosprofesional.form[0].telefono
                        : ''
                    "
                    placeholder=""
                    :rules="[(v) => !!v || 'requerido']"
                    required
                    filled
                    outlined
                    dense
                    class="margen"
                  />
                </v-col>
                <v-col md="3" sm="6">
                  <v-text-field
                    name="expedida"
                    label="Expedida por"
                    :value="
                      datosprofesional.form.length != 0
                        ? datosprofesional.form[0].expedida
                        : ''
                    "
                    placeholder=""
                    :rules="[(v) => !!v || 'requerido']"
                    required
                    filled
                    outlined
                    dense
                    class="margen"
                  />
                </v-col>
                <v-col md="3" sm="6">
                  <v-autocomplete
                    :items="tit_habilitantes"
                    item-text="t_deta"
                    item-value="t_cod"
                    placeholder="Seleccione un titulo habilitante"
                    :rules="[(v) => !!v || 'requerido']"
                    required
                    v-model="combo_titular_habilitante"
                    outlined
                    filled
                    dense
                    class="margen"
                    label="Tit.Habilitante"
                  ></v-autocomplete>
                </v-col>
                <v-col md="6" sm="12">
                  <v-text-field
                    name="matricula_profesional"
                    label="MATRÍCULA PROFESIONAL"
                    :value="
                      datosprofesional.form.length != 0
                        ? datosprofesional.form[0].matricula
                        : ''
                    "
                    placeholder=""
                    :rules="[(v) => !!v || 'requerido']"
                    required
                    filled
                    outlined
                    dense
                    class="margen"
                  />
                </v-col>
                <v-col md="6" sm="12">
                  <v-text-field
                    name="matricula_municipal"
                    label="MATRICULA MUNICIPAL"
                    :value="
                      datosprofesional.form.length != 0
                        ? datosprofesional.form[0].escribania
                        : ''
                    "
                    placeholder=""
                    filled
                    outlined
                    dense
                    class="margen"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col md="6" style="background-color: #b3e5fc">
              <span style="color: #f00">*</span>
              <strong
                >En caso de no contar con Matricula Municipal deberá adjuntar la
                siguiente documentación en un solo archivo PDF:</strong
              >
              <ul>
                <li>Foto Carnet 3x3cm</li>
                <li>Titulo</li>
                <li>DNI</li>
                <li>Último pago de monotributo</li>
                <li>Inscripción en Ingresos Brutos</li>
                <li>Nº de Legajo Caja Previsional</li>
                <li>Matrícula Profesional</li>
                <li>Último pago de Matrícula</li>
              </ul>
            </v-col>
            <v-col md="6">
              <!-- <v-file-input
                  v-model="files"
                  label="Archivos"
                  placeholder="Arrastre o haga click para agregar el archivo"
                  show-size
                  multiple
              ></v-file-input> -->
              <v-file-input
                chips
                filled
                show-size
                :rules="[fileSizeRule]"
                name="adjunto"
                v-model="files"
                label="Archivo"
              ></v-file-input>
            </v-col>
            <!-- <v-row justify="center"> -->
            <!-- <div class="dropzone" style="position: relative;width: 80%;" @dragenter.prevent="handleDragEnter" @dragover.prevent="handleDragOver" @drop.prevent="handleDrop">
                <div class="dropzone dropzone-previews dz-clickable" id="adjuntoCatastro">
                    <div class="dz-message"  data-dz-message>
                        <v-file-input
                            v-model="files"
                            label="Archivos"
                            placeholder="Arrastre o haga click para agregar el archivo"
                            show-size
                            multiple
                            @change="handleFileUpload"
                        ></v-file-input>
                    </div>
                </div>
            </div> -->
            <!-- </v-row> -->
          </v-row>
        </v-card-text>
        <v-card-actions class="mt-10 justify-center">
          <v-btn
            dark
            color="claro"
            type="submit"
            id="btnenviarsolicitud"
            style="margin-bottom: 10px"
          >
            Enviar Solicitud
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
    <v-row
      justify="center"
      :class="
        datosprofesional.estado == 1 || datosprofesional.estado == 3
          ? 'full-height d-flex align-center'
          : ''
      "
    >
      <v-col cols="8" v-if="datosprofesional.estado == 1">
        <v-card class="solicitudes">
          <v-row align="center">
            <v-col cols="10">
              <v-card-title
                class="text-h4 font-weight-bold grey--text text--darken-2"
                >{{ datosprofesional.titulo }}</v-card-title
              >
              <v-card-subtitle class="text-h6 grey--text">{{
                datosprofesional.msj
              }}</v-card-subtitle>
            </v-col>
            <v-col cols="2">
              <v-icon class="icono" color="grey"
                >mdi-timer-sand-complete</v-icon
              >
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="8" v-if="datosprofesional.estado == 3">
        <v-card class="solicitudes">
          <v-row align="center">
            <v-col cols="10">
              <v-card-title
                class="text-h4 font-weight-bold grey--text text--darken-2"
                >{{ datosprofesional.titulo }}</v-card-title
              >
              <v-card-subtitle class="text-h6 grey--text text--darken-2">{{
                datosprofesional.msj
              }}</v-card-subtitle>
              <!-- <v-col cols="10"  class="mx-auto"> -->
              <v-divider></v-divider>
              <!-- </v-col> -->
              <v-col cols="12" class="mx-auto grey--text text--darken-2"
                >Puede editar el formulario para modificar los datos incorrectos
                y volver a realizar la inscripción.</v-col
              >
            </v-col>
            <v-col cols="2">
              <v-icon class="icono" color="grey">mdi-exclamation-thick</v-icon>
            </v-col>
          </v-row>
          <v-card-actions class="justify-center">
            <v-btn
              color="claro"
              dark
              small
              class="ml-3"
              @click="
                LlenarTitulos();
                datosprofesional.estado = 0;
              "
            >
              EDITAR FORMULARIO
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-col>
  <!-- </v-row> -->
  <!-- </v-app> -->
</template>

<script>
export default {
  props: {
    datosprofesional: Object,
  },
  data: () => ({
    selectedFile: null,
    selectedFile2: null,
    imageUrl: "",
    imageUrl2: "",
    validformsolicitudobra: true,
    file: null,
    // imageUrl: "https://nfs.lacosta.gob.ar/backend/pruebita/",
    controleditar: 0,
    user: { checkescri: {} },
    imagen: "",
    tit_habilitantes: [],
    files: [],
    combo_titular_habilitante: "",
  }),

  components: {},

  computed: {
    // imageUrl() {
    //   return URL.createObjectURL(this.selectedFile);
    // },
    // imageUrl2() {
    //   return URL.createObjectURL(this.selectedFile2);
    // },
  },

  watch: {},

  mounted() {
    this.LlenarTitulos();
    // console.log("datos", this.datosprofesional)
    // var obj = this;
    // obj.$on('datos-recibidos', function(datos) {
    //   console.log("entra", datos)
    //   obj.datosprofesional = datos;
    // });
  },

  methods: {
    // LLenarFormu(){
    //     this.datosprofesional=this.user;
    //     console.log("datos escri", this.datosprofesional)
    // },
    LlenarTitulos() {
      var obj = this;
      this.$ajax
        .get(`${this.$apitramites}funciones/titulosObrasPrivadas`)
        .then(function (response) {
          // obj.$emit('datos-recibidos', response);
          console.log("titulo habilitante", response);
          obj.tit_habilitantes = response.data;
          // obj.AbrirPrincipal();

          // if(response.data.estado==3){
          // }
          // obj.escribanos=response.data
        })
        .catch(function (error) {
          obj.snackbar = true;
          obj.msj = error;
        });
    },

    // handleFileUpload() {
    // // Manejar la lógica de carga de archivos aquí si es necesario
    // },
    // handleDragEnter(event) {
    // // Prevenir comportamientos predeterminados y agregar clases o estilos de realce si lo deseas
    // event.preventDefault();
    // },
    // handleDragOver(event) {
    // // Prevenir comportamientos predeterminados y agregar clases o estilos de realce si lo deseas
    // event.preventDefault();
    // },
    // handleDrop(event) {
    // // Prevenir comportamientos predeterminados y manejar la caída de archivos
    // event.preventDefault();

    // // Acceder a la lista de archivos desde el evento
    // const droppedFiles = event.dataTransfer.files;

    // // Puedes hacer algo con los archivos aquí, por ejemplo, almacenarlos en la variable files
    // this.files = Array.from(droppedFiles);
    // },

    fileSizeRule(file) {
      // Si no hay archivo, no necesita validación
      if (!file) return true;

      // 15 MB en bytes (15 * 1024 * 1024 = 15728640)
      const maxSize = 15 * 1024 * 1024;

      // Si se selecciona más de un archivo, validamos cada uno
      if (Array.isArray(file)) {
        return (
          file.every((f) => f.size <= maxSize) ||
          "Cada archivo debe pesar menos de 15 MB"
        );
      }

      // Si es solo un archivo
      return file.size <= maxSize || "El archivo debe pesar menos de 15 MB";
    },

    EnviarSolicitud(event) {
      const formData = new FormData(event.target);
      formData.append(
        "combo_titular_habilitante",
        this.combo_titular_habilitante
      );
      console.log("datos asd", formData);
      if (this.$refs.formsolicitudobra.validate()) {
        var obj = this;
        this.$ajax
          .post(`${this.$apitramites}obrasprivadas/enviarSolicitud`, formData, {
            headers: { "Content-Type": "multipart/form-data" },
            responseType: "blob",
          })
          .then(function (response) {
            console.log("Enviar solicitud obras", response);
            if (response) {
              obj.ImprimirPdf(response.data);
              obj.$refs.formsolicitudobra.reset();
              obj.$emit("check");
            }
          })
          .catch(function (error) {
            console.log("error", error);
            let mensaje;
            switch (error.response.status) {
              case 410:
                mensaje = "Error al generar el PDF";
                break;
              default:
                mensaje = error.response.data.mensaje;
                break;
            }
            obj.$swal.fire({
              title: "Error",
              text: mensaje,
              icon: "error",
            });
          });
      }
    },
    async ImprimirPdf(pdf) {
      // var obj = this;
      if (pdf) {
        console.log("pdf", pdf);
        if (pdf.type === "application/json") {
          // Convertir el PDF a texto para extraer la información de estado
          const reader = new FileReader();
          reader.onload = function (event) {
            const text = event.target.result;
            const response = JSON.parse(text);
            if (response.estado) {
              // Si hay un error, mostrarlo en la alerta
              console.log("entra error");
              this.$swal.fire({
                title: "¡Atención!",
                text: response.msj,
                icon: "info",
              });
              // this.PruebaAlerta(response.msj)
            } else {
              // Si no hay errores, abrir el PDF en una nueva ventana
              const url = URL.createObjectURL(pdf);
              window.open(url);
            }
          }.bind(this);
          reader.readAsText(pdf);
        } else {
          // Si no es JSON, simplemente abrir el PDF en una nueva ventana
          const url = URL.createObjectURL(pdf);
          window.open(url);
          console.log("aca");
        }
      } else {
        console.error("No se proporcionó el PDF para imprimir.");
      }
    },
  },
};
</script>

<style scoped>
.borde {
  border: 2px solid #ccc;
  border-radius: 8px; /* Opcional: para esquinas redondeadas */
}
.icono {
  display: flex;
  align-items: center;
  font-size: 85px;
}
.margen {
  margin-top: -25px;
}
.dropzone {
  min-height: 150px;
  border: 9px solid rgba(0, 0, 0, 0.3);
  background: white;
  padding: 20px 20px;

  /* display: flex;
    justify-content: center;
    align-items: center; */
}
.dropzone .dz-message {
  text-align: center;
  margin: 3em 0;
}
.solicitudes {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid;
  background-color: #ccc;
  padding: 10px;
}
.full-height {
  height: 100vh;
}
</style>
