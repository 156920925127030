<template>
  <div>
    <v-row>
      <v-col cols="6">
        <div class="ml-2 font-weight-bold" style="font-size: 28px">
          Detalles de la cuenta
        </div>
        <v-row class="mt-4">
          <v-col
            md="4"
            v-for="estadistica in estadisticas"
            :key="estadistica.tabla"
          >
            <v-card
              class="mx-auto"
              color="claro"
              dark
              :to="AbrirDesdeInicio(estadistica.tabla)"
            >
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle
                    class="mb-2 text-h6"
                    style="text-transform: capitalize"
                  >
                    {{ estadistica.tabla }}
                  </v-list-item-subtitle>
                  <v-list-item-title
                    class="text-h4"
                    style="display: flex; justify-content: space-between"
                  >
                    {{ estadistica.cantidad }}
                    <v-icon
                      x-large
                      dark
                      style="font-size: 40px"
                      color="white"
                      class="text-right"
                      >{{ estadistica.icono }}</v-icon
                    >
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="6">
        <div class="ml-2 font-weight-bold" style="font-size: 28px; color: #333">
          Agenda
        </div>
        <!-- Verificar si la lista de agendas está vacía -->
        <template v-if="agendas.length == 0">
          <v-card class="mt-6" flat>
            <v-list-item class="d-flex align-center">
              <v-list-item-content
                class="ml-4"
                style="flex: 1; text-align: center"
              >
                <v-list-item-title
                  class="text-h5 mb-2 font-weight-medium"
                  style="color: #333"
                >
                  No hay resultados
                </v-list-item-title>
                <v-list-item-subtitle style="color: #666">
                  No se encontraron eventos en la agenda.
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </template>
        <!-- Mostrar las agendas si no está vacía -->
        <v-card
          v-else
          class="mt-6"
          flat
          v-for="agenda in agendas"
          :key="agenda.pkagenda"
        >
          <v-list-item class="d-flex align-start">
            <v-card width="60" class="mt-2 mb-2">
              <div class="calendar-item">
                <span class="calendar-month" style="color: white">
                  {{
                    new Date(agenda.fecha)
                      .toLocaleString("es-ES", { month: "short" })
                      .toUpperCase()
                  }}
                </span>
                <span class="calendar-date">{{
                  new Date(agenda.fecha).getDate()
                }}</span>
              </div>
            </v-card>
            <v-list-item-content
              class="ml-4"
              style="flex: 1; word-wrap: break-word"
            >
              <v-list-item-title
                class="text-h5 mb-2 font-weight-medium"
                style="color: #333"
              >
                {{ agenda.titulo }}
              </v-list-item-title>
              <v-list-item-subtitle
                style="color: #666; white-space: normal; overflow: visible"
              >
                {{ agenda.descripcion }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action class="pt-2">
              <a
                :href="agenda.link"
                target="_blank"
                style="text-decoration: none; color: #1976d2"
              >
                Visitar sitio
              </a>
            </v-list-item-action>
          </v-list-item>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "Principal",
  data: () => ({
    estadisticas: [],
    agendas: [],
  }),
  mounted() {
    console.log("Componente Principal montado");
    this.TraerEstadisticas();
  },
  methods: {
    TraerEstadisticas() {
      var obj = this;
      this.$ajax
        .post(`${this.$apitramites}users/traerEstadisticas`)
        .then(function (response) {
          console.log("traer estadisticas", response);
          obj.estadisticas = response.data;
          obj.TraerAgendas();
        })
        .catch(function (error) {
          obj.snackbarconfirm = true;
          obj.msj = error;
        });
    },
    TraerAgendas() {
      var obj = this;
      this.$ajax
        .get(`${this.$apitramites}funciones/Agenda`)
        .then(function (response) {
          console.log("traer agendas", response);
          obj.agendas = response.data;
        })
        .catch(function (error) {
          obj.snackbarconfirm = true;
          obj.msj = error;
        });
    },
    AbrirDesdeInicio(sistema) {
      switch (sistema) {
        case "cementerio":
          return "/cementerio";
        case "comercios":
          return "/comercio";
        case "vehiculos":
          return "/vehiculos";
        case "inmuebles":
          return "/inmueble";
        case "escribanos":
          return "/escribanos";
        default:
          return "/obrasprivadas";
      }
    },
  },
};
</script>
