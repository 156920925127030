<template>
  <v-row>
    <v-col cols="12">
      <v-data-table
        :headers="headerscementerios"
        :items="cementerios"
        :search="buscador"
        class="elevation-1 bordecolor"
        item-key="pkcementerio"
        :expanded.sync="expanded"
      >
        <template v-slot:top>
          <v-toolbar flat color="claro" dark>
            <v-toolbar-title>CEMENTERIO</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="buscador"
              append-icon="mdi-magnify"
              label="Buscar"
              filled
              dense
              outlined
              single-line
              hide-details
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-btn
              color="white"
              style="margin-top: 0px; margin-left: 15px"
              @click="DialogNuevoCementerio = true"
            >
              <h4 style="color: #000">Nuevo Cementerio</h4>
            </v-btn>
          </v-toolbar>
        </template>
        <template v-slot:[`item.estado`]="item">
          <span :class="laclase(item).class">{{ item.condi }} </span>
        </template>
        <template v-slot:[`item.editar`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                style="
                  background-color: #000;
                  width: 120%;
                  height: 60%;
                  border-radius: 5px;
                "
                v-bind="attrs"
                v-on="on"
                :disabled="expandedIsActive"
                small
                dark
                @click="AbrirEditarCementerio(item)"
              >
                mdi-pencil
              </v-icon>
            </template>
            <span>Editar</span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                style="
                  background-color: #000;
                  border-radius: 5px;
                  height: 33px;
                  width: 35px;
                "
                v-bind="attrs"
                v-on="on"
                :disabled="expandedIsActive"
                dark
                small
                @click="AbrirDialogDeudas(item)"
              >
                mdi-currency-usd
              </v-icon>
            </template>
            <span>Pagar deudas</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                style="
                  margin-left: 17px;
                  background-color: red;
                  border-radius: 5px;
                  height: 33px;
                  width: 35px;
                "
                v-bind="attrs"
                dark
                small
                :disabled="expandedIsActive"
                v-on="on"
                @click="EliminarCementerio(item)"
              >
                mdi-delete
              </v-icon>
            </template>
            <span>Eliminar cementerio</span>
          </v-tooltip>
        </template>
        <!-- <template v-slot:[`expanded-item`]="{ headers }">
          <td :colspan="headers.length" style="padding: 0px !important">
            <v-container fluid>
              <v-row no-gutters justify="center">
                <v-card
                  min-width="500"
                  v-if="showDetails == 'editcemen'"
                  class="bordecolor"
                >
                  <v-card-title class="headline">
                    <v-row justify="center" style="margin-top: 5px">
                      <v-icon
                        style="
                          background-color: #bdbdbd;
                          width: 30px;
                          height: 30px;
                          border-radius: 5px;
                          margin-right: 10px;
                        "
                        dark
                      >
                        mdi-pencil
                      </v-icon>
                      <b>Editar cementerio</b>
                    </v-row>
                  </v-card-title>
                  <v-card-text>
                    <v-text-field
                      v-model="editardescripcion"
                      label="Descripcion"
                      outlined
                      filled
                      dense
                      class="mt-6"
                      :rules="[(v) => !!v || 'Falta la descripcion']"
                      required
                    ></v-text-field>
                  </v-card-text>
                  <v-card-actions class="justify-center">
                    <v-btn
                      dark
                      color="error"
                      @click="expanded = []"
                      class="mr-2"
                    >
                      cancelar
                    </v-btn>
                    <v-btn color="claro" dark @click="EditarCementerio()">
                      Confirmar
                    </v-btn>
                  </v-card-actions>
                </v-card>
                <v-card class="ancho_tabla" v-if="showDetails == 'pagardeudas'">
                  <pagardeudas
                    ref="formDeuda"
                    @vaciarExpanded="vaciarExpanded"
                    @CargarTabla="ListadoCementerios"
                    :user="componentepagardeuda"
                  ></pagardeudas>
                </v-card>
              </v-row>
            </v-container>
          </td>
        </template> -->
        <template v-slot:no-data>
          <v-btn dark color="claro" @click="initialize">Cargar</v-btn>
        </template>
      </v-data-table>
      <pagardeudas
        ref="formDeuda"
        @vaciarExpanded="vaciarExpanded"
        @CargarTabla="ListadoCementerios"
        :user="componentepagardeuda"
      ></pagardeudas>
    </v-col>
    <v-dialog
      v-model="dialogeditar"
      persistent
      max-width="500"
      class="zindex-dialog"
    >
      <v-card>
        <v-card-title class="headline">
          <v-row justify="center" style="margin-top: 5px">
            <v-icon
              style="
                background-color: #bdbdbd;
                width: 30px;
                height: 30px;
                border-radius: 5px;
                margin-right: 10px;
              "
              dark
            >
              mdi-pencil
            </v-icon>
            <b>Editar cementerio</b>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="editardescripcion"
            label="Descripcion"
            outlined
            filled
            dense
            class="mt-6"
            :rules="[(v) => !!v || 'Falta la descripcion']"
            required
          ></v-text-field>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn dark color="error" @click="dialogeditar = false" class="mr-2">
            cancelar
          </v-btn>
          <v-btn color="claro" dark @click="EditarCementerio()">
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="DialogNuevoCementerio" width="500" persistent>
      <v-card class="bordecolor">
        <v-form
          @submit.prevent="AgregarCementerio"
          ref="formnuevocementerio"
          v-model="validformnuevocementerio"
        >
          <v-card-title class="font-weight-bold">
            <v-row justify="center" style="margin-top: 5px">
              Agregar Nuevo Cementerio
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-row class="mt-2">
              <v-col cols="12">
                <v-text-field
                  label="Padrón"
                  name="padron"
                  :rules="[(v) => !!v || 'Falta el padrón']"
                  required
                  color="#40A5DD"
                  outlined
                  filled
                  type="number"
                ></v-text-field>
              </v-col>
              <v-col cols="12" style="margin-top: -15px">
                <v-text-field
                  name="descripcion"
                  label="Descripcion"
                  placeholder="Nombre de referencia"
                  :rules="[(v) => !!v || 'Falta la descripcion']"
                  required
                  color="#40A5DD"
                  filled
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
            <v-checkbox
              name="titular"
              v-model="checkboxtitular"
              :value="1"
              color="#40A5DD"
            >
              <template v-slot:label>
                <!-- <div> -->
                Declaro ser titular / apoderado. Es necesario para solicitar
                determinados trámites.
                <!-- </div> -->
              </template>
            </v-checkbox>
          </v-card-text>
          <!-- <v-divider></v-divider> -->
          <v-card-actions class="justify-center">
            <v-btn @click="DialogNuevoCementerio = false" color="error">
              cancelar
            </v-btn>
            <v-btn color="claro" dark type="submit" id="botonagregarcementerio">
              Guardar
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import pagardeudas from "../Cementerio/Pagar_deuda_cementerio";
export default {
  data: () => ({
    dialogeditar: false,
    timeout: 3000,
    msj: "",
    expanded: [],
    showDetails: "",
    headerscementerios: [
      { text: "DESCRIPCION", align: "start", value: "descripcion" },
      { align: "start", value: "editar", width: 55 },
      { text: "NOMBRE", value: "fapenom", align: "start" },
      { text: "ORDEN", value: "num_orden", align: "start" },
      { text: "ESTADO", value: "estado" },
      {
        text: "OPCIONES",
        value: "actions",
        align: "right",
        width: 300,
        sortable: false,
      },
    ],
    cementerios: [],
    cementerio: "",
    buscador: "",
    datevencimiento: new Date().toISOString().substr(0, 10),
    DialogNuevoCementerio: false,
    descripcion: "",
    checkboxtitular: false,
    validformnuevocementerio: true,
    DialogEliminarCementerio: false,
    pkcementerio: "",
    editardescripcion: "",
    datoscementerio: "",
  }),

  components: {
    pagardeudas,
  },

  computed: {
    componentepagardeuda() {
      return {
        vencimiento: this.datevencimiento,
        datoscementerio: this.datoscementerio,
      };
    },
    expandedIsActive() {
      return this.expanded.length > 0;
    },
  },

  watch: {},

  mounted() {
    this.initialize();
  },

  methods: {
    initialize() {
      this.ListadoCementerios();
    },

    ListadoCementerios() {
      var obj = this;
      this.$ajax
        .get(`${this.$apitramites}cementerios/traerCementerios`)
        .then(function (response) {
          console.log("Listado cementerios", response);
          obj.cementerios = response.data;
        })
        .catch(function (error) {
          console.log(error);
          // obj.$swal.fire({
          //     title: "Error",
          //     text: "Sin datos cargados",
          //     icon: "error"
          //   });
        });
    },

    // EliminarCementerio(){
    //   // alert(this.pkcementerio);
    //   var obj = this;
    //     this.$ajax
    //     .delete(`${this.$apitramites}cementerios/${this.pkcementerio}`)
    //     .then(function(response) {
    //       console.log("Eliminar cementerio", response);
    //       obj.snackbarconfirm=true;
    //       obj.msj= "Cementerio eliminado con exito!!!";
    //       obj.ListadoCementerios();
    //       obj.DialogEliminarCementerio=false;
    //     })
    //     .catch(function(error) {
    //       obj.snackbar=true;
    //       obj.msj= error;
    //     });
    // },

    EliminarCementerio(item) {
      this.pkcementerio = item.pkcementerio;
      console.log(item);
      var obj = this;
      this.$swal
        .fire({
          title: "¿Desea eliminar este cementerio?",
          text: "¡Esta acción no se puede revertir!",
          icon: "warning",
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: "No, cancelar!",
          confirmButtonText: "Sí, eliminar!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            obj.$ajax
              .delete(`${obj.$apitramites}cementerios/${obj.pkcementerio}`)
              .then(function (response) {
                console.log("Eliminar cementerio", response);
                obj.$swal.fire({
                  title: "¡Eliminado!",
                  text: "Cementerio eliminado con éxito",
                  icon: "success",
                });
                obj.ListadoCementerios();
              })
              .catch(function (error) {
                console.error("Error al eliminar cementerio", error);
                obj.$swal.fire({
                  title: "Error",
                  text: "Hubo un error al eliminar el cementerio",
                  icon: "error",
                });
              });
          }
        });
    },

    AbrirEditarCementerio(item) {
      // (showDetails = "editcemen"), (expanded = [item]);
      this.editardescripcion = item.descripcion;
      this.pkcementerio = item.pkcementerio;
      this.dialogeditar = true;
    },

    EditarCementerio() {
      var obj = this;
      this.$ajax
        .patch(`${this.$apitramites}cementerios/${this.pkcementerio}`, {
          descripcion: this.editardescripcion,
        })
        .then(function (response) {
          console.log("Editar cementerio", response);
          obj.$swal.fire({
            title: "¡Editado!",
            text: "Cementerio editado con éxito",
            icon: "success",
          });
          obj.expanded = [];
          obj.ListadoCementerios();
        })
        .catch(function (error) {
          obj.$swal.fire({
            title: "Error",
            text: error,
            icon: "error",
          });
        });
    },

    laclase(item) {
      if (item.condicontri != "2") {
        if (item.t_desvie == "1" && item.t_lugar == "1") {
          return { class: "green--text", restringido: false };
        } else if (
          ((item.t_desvie == "1" && item.t_lugar == "0") ||
            (item.t_desvie == "0" && item.t_lugar == "1")) &&
          item.t_web == "1"
        ) {
          return { class: "orange--text", restringido: false };
        } else {
          return { class: "red--text", restringido: true };
        }
      } else {
        return { class: "red--text", restringido: true };
      }
    },

    AgregarCementerio(event) {
      var obj = this;
      if (this.$refs.formnuevocementerio.validate()) {
        console.log("hola", Object.fromEntries(new FormData(event.target)));
        let objetito = Object.fromEntries(new FormData(event.target));
        objetito.padron = Number(objetito.padron);

        this.$ajax
          .post(`${this.$apitramites}cementerios`, objetito)
          .then(function (response) {
            console.log("Nuevo cementerio", response);
            obj.$swal.fire({
              title: "¡Agregado!",
              text: "Cementerio cargado con éxito",
              icon: "success",
            });
            obj.ListadoCementerios();
            obj.DialogNuevoCementerio = false;
            obj.$refs.formnuevocementerio.reset();
          })
          .catch(function (error) {
            let mensaje = "Error, intente nuevamente";
            switch (error.response.data.message) {
              case "NOT_EXIST":
                mensaje = "No se encuentra el N° de orden";
                break;
              case "ROW_EXIST":
                mensaje = "El N° de orden ya está asociado a su cuenta";
                break;
            }
            obj.$swal.fire({
              title: "Error",
              text: mensaje,
              icon: "error",
            });
          });
      }
    },

    AbrirDialogDeudas(item) {
      // (showDetails = 'pagardeudas'),
      //               (expanded = [item]),
      this.datoscementerio = item;
      this.$nextTick(() => {
        this.$refs["formDeuda"].ListadoCuotas(item);
      });
    },

    vaciarExpanded() {
      this.expanded = [];
    },
  },
};
</script>
