<template>
  <!-- <v-app style="width:100%;"> -->
  <!-- <v-container fluid> -->
  <v-row style="margin-top: -65px">
    <principal
      ref="formprincipal"
      :datosprofesional="checkobra"
      @check="CheckObras"
    ></principal>
    <v-col cols="12" v-if="this.checkobra.estado == 2">
      <!-- <v-col cols="12" v-if="this.checkobra.estado==1"> -->
      <!-- :show-expand="(expanded=='') ? false : true" -->
      <!-- Por si me pinta mostrar el expand -->
      <v-data-table
        :headers="headersobras"
        :items="obras"
        :search="buscador"
        class="elevation-1 bordecolor"
        item-key="pktramite"
        :expanded.sync="expanded"
      >
        <template v-slot:top>
          <v-toolbar flat color="claro" dark>
            <v-toolbar-title>OBRAS PRIVADAS</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="buscador"
              append-icon="mdi-magnify"
              label="Buscar"
              filled
              dense
              outlined
              single-line
              hide-details
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-btn
              color="white"
              style="margin-top: 0px; margin-left: 15px"
              @click="TraerValores()"
            >
              <h4 style="color: #000">Nuevo Trámite</h4>
            </v-btn>
          </v-toolbar>
        </template>
        <template v-slot:[`item.estado`]="{ item }">
          <span :class="Estado(item)">{{ item.estado }}</span>
          <v-btn
            @click="MotivoRechazo(item.motivoRechazo)"
            v-if="
              item.fkestado == 3 ||
              item.fkestado == 9 ||
              item.fkestado == 11 ||
              item.fkestado == 12
            "
            dense
            style="height: 28px; font-size: smaller; width: 75px"
            color="grey"
            dark
            class="ml-2"
            >Motivo</v-btn
          >
        </template>
        <template v-slot:[`item.nomenclatura`]="{ item }">
          {{ item.nomenclatura }}
        </template>
        <template v-slot:[`item.fecha_modificacion`]="{ item }">
          {{ momento(item.fecha_modificacion) }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip bottom v-if="item.fkestado == 7 || item.fkestado == 9">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                style="
                  background-color: #000;
                  border-radius: 5px;
                  height: 33px;
                  width: 35px;
                  margin-left: 17px;
                "
                v-bind="attrs"
                v-on="on"
                :disabled="expandedIsActive"
                dark
                @click="(pktramite = item.pktramite), (DialogPlano = true)"
              >
                mdi-file-upload-outline
              </v-icon>
            </template>
            <span>Subir Plano</span>
          </v-tooltip>
          <v-tooltip bottom v-if="item.fkestado == 2">
            <template v-slot:activator="{ on, attrs }">
              <a :href="ruta + item.adjunto" target="_blank">
                <v-icon
                  style="
                    background-color: #000;
                    border-radius: 5px;
                    height: 33px;
                    width: 35px;
                    margin-left: 17px;
                  "
                  v-bind="attrs"
                  v-on="on"
                  :disabled="expandedIsActive"
                  dark
                >
                  mdi-file-search-outline
                </v-icon>
              </a>
            </template>
            <span>Ver Plano</span>
          </v-tooltip>
          <v-tooltip bottom v-if="item.fkestado == 12 || item.fkestado == 5">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                style="
                  background-color: #000;
                  border-radius: 5px;
                  height: 33px;
                  width: 35px;
                  margin-left: 17px;
                "
                v-bind="attrs"
                v-on="on"
                :disabled="expandedIsActive"
                dark
                @click="AbrirFormulario(item, true)"
              >
                mdi-eye-outline
              </v-icon>
            </template>
            <span>Ver Ficha</span>
          </v-tooltip>
          <v-tooltip
            bottom
            v-if="
              item.fkestado == 3 || item.fkestado == 11 || item.fkestado == 5
            "
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                style="
                  background-color: #000;
                  border-radius: 5px;
                  height: 33px;
                  width: 35px;
                  margin-left: 17px;
                "
                v-bind="attrs"
                v-on="on"
                :disabled="expandedIsActive"
                dark
                @click="AbrirFormulario(item, false)"
              >
                mdi-form-select
              </v-icon>
            </template>
            <span>Editar formulario</span>
          </v-tooltip>
          <formulario
            ref="formuobras"
            @vaciarExpanded="vaciarExpanded"
            @actualizarTabla="initialize"
            :user="ComponenteObra"
          ></formulario>
        </template>
        <template v-slot:[`expanded-item`]="{ headers }">
          <td :colspan="headers.length" style="padding: 0px !important">
            <v-container>
              <v-row no-gutters justify="center">
                <v-card width="800" v-if="showDetails == 'formuobras'" flat>
                  <formulario
                    ref="formuobras"
                    @vaciarExpanded="vaciarExpanded"
                    @actualizarTabla="initialize"
                    :user="ComponenteObra"
                  ></formulario>
                </v-card>
              </v-row>
            </v-container>
          </td>
        </template>
        <template v-slot:no-data>
          <v-btn dark color="claro" @click="initialize">Cargar</v-btn>
        </template>
      </v-data-table>
    </v-col>

    <v-dialog v-model="DialogNuevoTramite" width="800" persistent>
      <v-card class="bordecolor" v-show="!alerta">
        <v-card-title class="font-weight-bold">
          <v-row justify="center" style="margin-top: 5px">
            Agregar Nuevo Trámite
          </v-row>
        </v-card-title>
        <v-card-text class="mt-6">
          <v-form
            ref="formnuevotramite"
            v-model="validformnuevotramite"
            lazy-validation
          >
            <div>
              <v-row>
                <v-col cols="3">
                  <v-text-field
                    v-model="nomenclatura.circunscripcion"
                    label="Circunscripción"
                    :rules="[(v) => !!v || 'Falta la circunscripción']"
                    required
                    filled
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    v-model="nomenclatura.seccion"
                    label="Sección"
                    :rules="[(v) => !!v || 'Falta la sección']"
                    required
                    filled
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    v-model="nomenclatura.manzana"
                    label="Manzana"
                    :rules="[(v) => !!v || 'Falta la manzana']"
                    required
                    filled
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    v-model="nomenclatura.letra_manzana"
                    label="Letra manz."
                    filled
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row style="margin-top: -15px">
                <v-col cols="3">
                  <v-text-field
                    v-model="nomenclatura.parcela"
                    label="Parcela"
                    :rules="[(v) => !!v || 'Falta la parcela']"
                    required
                    filled
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    v-model="nomenclatura.letra_parcela"
                    label="Letra parc."
                    filled
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    v-model="nomenclatura.unidad_funcional"
                    label="Unidad func."
                    filled
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    v-model="nomenclatura.chacra"
                    label="Chacra"
                    filled
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row style="margin-top: -15px">
                <v-col cols="3">
                  <v-text-field
                    v-model="nomenclatura.letra_chacra"
                    label="Letra chacra"
                    filled
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    v-model="nomenclatura.quinta"
                    label="Quinta"
                    filled
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    v-model="nomenclatura.fraccion"
                    label="Fracción"
                    filled
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
            </div>

            <div style="display: flex">
              <v-row>
                <v-col cols="12">
                  <v-autocomplete
                    v-model="nomenclatura.sellado"
                    :items="valores"
                    label="Valor del Sellado"
                    item-text="t_deta"
                    item-value="t_cod"
                    required
                    :rules="[
                      (v) =>
                        (v !== null && v !== undefined) ||
                        'Falta el valor de sellado',
                    ]"
                    filled
                    dense
                  >
                    <template v-slot:item="data">
                      {{ data.item.t_deta }} ${{ data.item.t_valor }}
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
              <!-- <v-col cols="6">
                    <v-menu
                      v-model="menufecha"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="date"
                          label="Fecha"
                          prepend-icon="mdi-calendar"
                          filled
                          dense
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="date"
                        @input="menufecha = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col> -->
            </div>
          </v-form>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn
            @click="
              $refs.formnuevotramite.reset();
              DialogNuevoTramite = false;
            "
            color="error"
          >
            cancelar
          </v-btn>
          <v-btn color="claro" dark @click="NuevoTramite()"> Guardar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="DialogPlano" width="400" persistent>
      <v-card class="bordecolor">
        <v-card-title class="font-weight-bold">
          <v-row justify="center" style="margin-top: 5px"> Subir Plano </v-row>
        </v-card-title>
        <v-card-text class="mt-8">
          <v-file-input
            label="Archivo"
            v-model="plano"
            placeholder="Seleccione un archivo"
            outlined
            prepend-icon=""
            prepend-inner-icon="mdi-paperclip"
            show-size
          >
            <template v-slot:selection="{ index, text }">
              <v-chip v-if="index < 2" color="oscuro" dark label small>
                {{ text }}
              </v-chip>
            </template>
          </v-file-input>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn @click="DialogPlano = false" color="error"> cancelar </v-btn>
          <v-btn color="claro" dark @click="SubirPlano(plano)"> Guardar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- <div id="app">
          <div class="blur-background" v-show="alerta"></div>
          <div class="alert-container" v-show="alerta" style="z-index: 9999;">
            <v-alert
              
              type="error"
              outlined
              
              class="alert error-alert"
            >
              <template v-slot:icon="{ attrs }">
                <v-icon v-bind="attrs" class="custom-alert-icon">mdi-alert</v-icon>
              </template>
              <h2>{{ msj }}</h2>
              <v-btn 
                icon 
                class="close-button" 
                @click="alerta=false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-alert>
          </div>
        </div> -->
    <v-snackbar v-model="snackbarconfirm" :timeout="timeout" top>
      {{ msj }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="blue"
          text
          v-bind="attrs"
          @click="snackbarconfirm = false"
        >
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
  </v-row>

  <!-- </v-container> -->

  <!-- </v-app> -->
</template>

<script>
import formulario from "../ObrasPrivadas/Formulario_obras";
import principal from "../ObrasPrivadas/Principal_obras";
export default {
  data: () => ({
    alerta: false,
    snackbarconfirm: false,
    timeout: 3000,
    msj: "",
    expanded: [],
    showDetails: "",
    headersobras: [
      { text: "N° DE TRÁMITE", align: "start", value: "pktramite" },
      { text: "NOMENCLATURA", value: "nomemclatura", align: "start" },
      { text: "ESTADO", value: "estado", align: "start" },
      { text: "FECHA", value: "fecha_modificacion" },
      {
        text: "OPCIONES",
        value: "actions",
        align: "right",
        width: 300,
        sortable: false,
      },
    ],
    obras: [],
    obra: "",
    buscador: "",
    datevencimiento: new Date().toISOString().substr(0, 10),
    pktramite: "",
    datostramite: {},
    DialogNuevoTramite: false,
    validformnuevotramite: true,
    nomenclatura: {
      circunscripcion: "",
      seccion: "",
      manzana: "",
      letramanzana: "",
      parcela: "",
      letraparcela: "",
      unidadfunc: "",
      chacra: "",
      letrachacra: "",
      quinta: "",
      fraccion: "",
    },
    menufecha: false,
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    valores: [],
    disabled: false,
    checkobra: {},
    DialogPlano: false,
    plano: null,
    ruta: "https://nfs.lacosta.gob.ar/backend/tramites/obrasprivadas/planos/",
  }),

  components: {
    formulario,
    principal,
  },

  computed: {
    ComponenteObra() {
      return {
        tramite: this.datostramite,
        disabled: this.disabled,
        checkobra: this.checkobra,
      };
    },
    expandedIsActive() {
      return this.expanded.length > 0;
    },
  },

  watch: {},

  mounted() {
    this.initialize();
  },

  methods: {
    initialize() {
      this.CheckObras();
      this.ListadoObras();
    },

    // PruebaAlerta(mensaje){
    //   this.msj = mensaje;
    //   this.alerta = true;
    // },

    Estado(item) {
      switch (item.fkestado) {
        case 11:
        case 12:
          return "color-red"; // rojo
        case 2:
          return "color-green"; // verde
        case 7:
        case 4:
          return "color-orange"; // naranja
        default:
          return "";
      }
    },

    CheckObras() {
      var obj = this;
      this.$ajax
        .get(`${this.$apitramites}obrasprivadas/checkObrasPrivadas`)
        .then(function (response) {
          console.log("check obra", response);
          obj.checkobra = response.data;
        })
        .catch(function (error) {
          obj.snackbar = true;
          obj.msj = error;
        });
    },

    momento(fecha) {
      // console.log("fecha", fecha)
      if (fecha == "0000-00-00") {
        return "00-00-0000";
      } else {
        return this.moment(fecha).utc().format("DD/MM/YYYY");
      }
    },

    ListadoObras() {
      var obj = this;
      this.$ajax
        .get(`${this.$apitramites}obrasprivadas/traerTramites`)
        .then(function (response) {
          console.log("Listado Obras", response);
          obj.obras = response.data;
        })
        .catch(function (error) {
          console.log(error);
          obj.$swal.fire({
            title: "Error",
            text: "Sin datos cargados",
            icon: "error",
          });
        });
    },

    laclase(item) {
      if (item.condicontri != "2") {
        if (item.t_desvie == "1" && item.t_lugar == "1") {
          return { class: "green--text", restringido: false };
        } else if (
          ((item.t_desvie == "1" && item.t_lugar == "0") ||
            (item.t_desvie == "0" && item.t_lugar == "1")) &&
          item.t_web == "1"
        ) {
          return { class: "orange--text", restringido: false };
        } else {
          return { class: "red--text", restringido: true };
        }
      } else {
        return { class: "red--text", restringido: true };
      }
    },

    NuevoTramite() {
      console.log("sellado", this.nomenclatura.sellado);
      if (this.$refs.formnuevotramite.validate()) {
        console.log("si bebe", this.nomenclatura);
        var obj = this;
        this.$ajax({
          method: "post",
          url: `${this.$apitramites}obrasprivadas`,
          responseType: "blob",
          data: this.nomenclatura,
        })
          .then(function (response) {
            console.log("nuevo tramite", response);
            obj.ImprimirPdf(response.data);
          })
          .catch(function (error) {
            obj.$swal.fire({
              title: "Error",
              text: error.response.data.mensaje,
              icon: "error",
            });
          });
      }
    },

    async ImprimirPdf(pdf) {
      var obj = this;
      if (pdf) {
        console.log("pdf", pdf);
        if (pdf.type === "application/json") {
          console.log("hola");
          const reader = new FileReader();
          reader.onload = function (event) {
            const text = event.target.result;
            const response = JSON.parse(text);
            if (response.estado == 2) {
              console.log("entra error");
              obj.alerta = true;
              obj.$swal
                .fire({
                  title: response.msj,
                  icon: "warning",
                  showCancelButton: true,
                  reverseButtons: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  cancelButtonText: "No, cancelar!",
                  confirmButtonText: "Sí, confirmar!",
                })
                .then((result) => {
                  if (result.isConfirmed) {
                    obj.nomenclatura.aceptarNuevo = 1;
                    obj
                      .$ajax({
                        method: "post",
                        url: `${obj.$apitramites}obrasprivadas`,
                        responseType: "blob",
                        data: obj.nomenclatura,
                      })
                      .then(function (response) {
                        console.log("nuevo tramite", response);
                        obj.ImprimirPdf(response.data);
                        obj.alerta = false;
                      })
                      .catch(function (error) {
                        console.log("estoy aca", error);
                        let mensaje = "Error, intente nuevamente";
                        switch (error.response.data.status) {
                          case 410:
                            mensaje = "Error, cuota duplicada";
                            break;
                        }
                        obj.$swal.fire({
                          title: "Error",
                          text: mensaje,
                          icon: "error",
                        });
                      });
                  } else {
                    obj.$refs.formnuevotramite.reset();
                    obj.alerta = false;
                    obj.DialogNuevoTramite = false;
                  }
                });

              // this.PruebaAlerta(response.msj)
            } else {
              // Si no hay errores, abrir el PDF en una nueva ventana
              const url = URL.createObjectURL(pdf);
              window.open(url);
            }
          }.bind(this);
          reader.readAsText(pdf);
        } else {
          // Si no es JSON, simplemente abrir el PDF en una nueva ventana
          const url = URL.createObjectURL(pdf);
          window.open(url);
          console.log("aca");
          obj.$swal.fire({
            title: "¡Exito!",
            text: "Solicitud enviada con exito.",
            icon: "success",
          });
          obj.DialogNuevoTramite = false;
          obj.$refs.formnuevotramite.reset();
          (obj.nomenclatura = {
            circunscripcion: "",
            seccion: "",
            manzana: "",
            letramanzana: "",
            parcela: "",
            letraparcela: "",
            unidadfunc: "",
            chacra: "",
            letrachacra: "",
            quinta: "",
            fraccion: "",
          }),
            obj.ListadoObras();
        }
      } else {
        console.error("No se proporcionó el PDF para imprimir.");
      }
    },

    TraerValores() {
      var obj = this;
      this.$ajax
        .get(`${this.$apitramites}funciones/preciosObrasPrivadas`)
        .then(function (response) {
          console.log("valores sellado", response);
          obj.valores = response.data;
          obj.DialogNuevoTramite = true;
        })
        .catch(function (error) {
          obj.snackbarconfirm = true;
          obj.msj = error;
        });
    },

    SubirPlano(adjunto) {
      if (adjunto) {
        var obj = this;
        let data = new FormData();
        data.append("adjunto", adjunto);
        data.append("pktramite", this.pktramite);
        console.log("data", data);
        this.$ajax({
          method: "post",
          url: `${this.$apitramites}obrasprivadas/enviarPlano`,
          responseType: "blob",
          data: data,
        })
          .then(function (response) {
            console.log("enviar plano", response);
            obj.$swal.fire({
              title: "¡Exito!",
              text: "El plano ha sido cargado correctamente",
              icon: "success",
            });
            obj.ListadoObras();
            obj.DialogPlano = false;
            obj.plano = null;
          })
          .catch(function (error) {
            obj.$swal.fire({
              title: "Error",
              text: error,
              icon: "error",
            });
          });
      } else {
        this.$swal.fire({
          title: "Error",
          text: "No hay ningún archivo cargado",
          icon: "error",
        });
      }
    },

    MotivoRechazo(motivo) {
      this.$swal.fire({
        title: "Motivo de rechazo",
        text: motivo,
      });
    },

    AbrirFormulario(item, valor) {
      this.disabled = valor;
      // (showDetails = 'formuobras'),
      // (expanded = [item]),
      this.datostramite = item;
      this.$nextTick(() => {
        this.$refs["formuobras"].TraerTiposObras();
      });
    },

    vaciarExpanded() {
      this.expanded = [];
    },
  },
};
</script>

<style scoped>
.v-dialog-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
}

#app {
  position: relative;
}

.blur-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(
    5px
  ); /* Ajusta el valor de desenfoque según lo necesites */
  z-index: 1;
}

.alert-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.alert {
  z-index: 2;
}

.error-alert {
  animation: shake 0.5s ease-in-out infinite alternate;
  background-color: #ffcccc !important;
  padding: 30px;
}

@keyframes shake {
  from {
    transform: translateX(-3px);
  }
  to {
    transform: translateX(3px);
  }
}

.custom-alert-icon {
  font-size: 35px; /* Puedes ajustar este valor según tu preferencia */
}
.close-button {
  position: absolute;
  top: 5px; /* Ajusta la posición vertical según tus necesidades */
  right: 5px; /* Ajusta la posición horizontal según tus necesidades */
  /* background-color: #ffffff; Cambia el color de fondo del botón si es necesario */
  /* border: 1px solid #000000; Cambia el color del borde si es necesario */
  border-radius: 50%; /* Ajusta el radio del borde para que sea circular */
  padding: 5px; /* Ajusta el espacio interior del botón según tus necesidades */
  z-index: 999; /* Ajusta el valor z-index para que esté por encima del contenido del alert */
}
.color-red {
  color: red;
}

.color-green {
  color: green;
}

.color-orange {
  color: orange;
}
</style>
