<template>
  <!-- <v-form @submit.prevent="EnviarSolicitud" ref="formtramite" v-model="validform" class="formu"> -->
  <v-dialog
    v-model="dialog"
    fullscreen
    persistent
    hide-overlay
    scrollable
    transition="dialog-bottom-transition"
    class="zindex-dialog"
  >
    <v-form
      @submit.prevent="EnviarSolicitud"
      ref="formtramite"
      v-model="validform"
      class="formu"
      lazy-validation
    >
      <v-card>
        <v-app-bar color="oscuro" fixed dark>
          <v-spacer></v-spacer>
          <v-toolbar-title class="mayuscula"
            >{{ user.disabled ? "ficha" : "editar formulario" }} del trámite
            {{ datostramite.pktramite }}</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog = false"><v-icon>mdi-close</v-icon></v-btn>
        </v-app-bar>
        <v-card-text class="margenappbar">
          <v-container fluid>
            <v-row>
              <v-col cols="12" md="3">
                <v-card>
                  <v-toolbar color="claro" dark flat>
                    <v-toolbar-title>Titular</v-toolbar-title>
                  </v-toolbar>
                  <v-card-text>
                    <v-row>
                      <v-col md="12">
                        <v-text-field
                          :readonly="user.disabled"
                          label="DNI/CUIT"
                          :rules="[(v) => !!v || 'Falta el dni/cuit']"
                          required
                          :value="
                            datosformulario.tramite
                              ? datosformulario.tramite[0].dni_tit
                              : ''
                          "
                          name="titular_dni"
                          dense
                          filled
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col md="12">
                        <v-text-field
                          :readonly="user.disabled"
                          :value="
                            datosformulario.tramite
                              ? datosformulario.tramite[0].nombre_tit
                              : ''
                          "
                          label="NOMBRE"
                          :rules="[(v) => !!v || 'Falta el nombre']"
                          required
                          class="margen"
                          name="titular_nombre"
                          dense
                          filled
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col md="12">
                        <v-text-field
                          :readonly="user.disabled"
                          :value="
                            datosformulario.tramite
                              ? datosformulario.tramite[0].apellido_tit
                              : ''
                          "
                          label="APELLIDO"
                          class="margen"
                          :rules="[(v) => !!v || 'Falta el apellido']"
                          required
                          name="titular_apellido"
                          dense
                          filled
                          outlined
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="9">
                <v-card>
                  <v-toolbar color="claro" dark flat>
                    <v-toolbar-title>Formulario</v-toolbar-title>
                  </v-toolbar>
                  <v-card-text>
                    <v-row>
                      <v-col md="6">
                        <v-text-field
                          :readonly="user.disabled"
                          :value="
                            datosformulario.tramite
                              ? datosformulario.tramite[0].di_calle
                              : ''
                          "
                          label="CALLE"
                          :rules="[(v) => !!v || 'Falta la calle']"
                          required
                          name="calle"
                          dense
                          filled
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col md="2">
                        <v-text-field
                          :readonly="user.disabled"
                          :value="
                            datosformulario.tramite
                              ? datosformulario.tramite[0].di_nro
                              : ''
                          "
                          label="N°"
                          :rules="[(v) => !!v || 'Falta el número']"
                          required
                          name="num_calle"
                          dense
                          filled
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col md="2">
                        <v-text-field
                          :readonly="user.disabled"
                          :value="
                            datosformulario.tramite
                              ? datosformulario.tramite[0].di_piso
                              : ''
                          "
                          label="PISO"
                          name="piso"
                          dense
                          filled
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col md="2">
                        <v-text-field
                          :readonly="user.disabled"
                          :value="
                            datosformulario.tramite
                              ? datosformulario.tramite[0].di_dpto
                              : ''
                          "
                          label="DPTO"
                          name="dpto"
                          dense
                          filled
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col md="6" class="margen">
                        <v-text-field
                          readonly
                          :value="
                            datosformulario.padron
                              ? datosformulario.padron[0].cuenta +
                                '/' +
                                datosformulario.padron[0].d_vefi
                              : ''
                          "
                          label="CUENTA"
                          :rules="[(v) => !!v || 'Falta la cuenta']"
                          required
                          name="cuenta_oc"
                          dense
                          filled
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col md="6" class="margen">
                        <v-text-field
                          :readonly="user.disabled"
                          :value="
                            datosformulario.tramite
                              ? datosformulario.tramite[0].insdominio
                              : ''
                          "
                          label="N° DOMINIO"
                          :rules="[(v) => !!v || 'Falta el número']"
                          required
                          name="dominio"
                          dense
                          filled
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col md="6" class="margen">
                        <v-text-field
                          :readonly="user.disabled"
                          :value="
                            datosformulario.tramites &&
                            datosformulario.tramites[0]
                              ? datosformulario.tramites[0].part_prov
                              : datosformulario.padron
                              ? datosformulario.padron[0].part_prov
                              : ''
                          "
                          label="PARTIDA PROVINCIAL"
                          :rules="[(v) => !!v || 'Falta la partida provincial']"
                          required
                          name="partida"
                          dense
                          filled
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col md="6" class="margen">
                        <v-autocomplete
                          :disabled="user.disabled"
                          :items="categorias"
                          :value="
                            datosformulario.tramite
                              ? datosformulario.tramite[0].categoria
                              : ''
                          "
                          item-text="t_deta"
                          item-value="t_cod"
                          placeholder="Seleccione una categoría"
                          :rules="[(v) => !!v || 'Seleccione una categoría']"
                          required
                          name="categoria"
                          outlined
                          filled
                          dense
                          label="CATEGORÍA"
                        ></v-autocomplete>
                      </v-col>
                      <v-col md="6" class="margen">
                        <v-text-field
                          :readonly="user.disabled"
                          :disabled="user.disabled"
                          :value="
                            datosformulario.tramite
                              ? datosformulario.tramite[0].observaciones
                              : ''
                          "
                          label="OBSERVACIONES"
                          name="observacion"
                          dense
                          filled
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col md="6" class="margen">
                        <v-text-field
                          :readonly="user.disabled"
                          :disabled="user.disabled"
                          :value="
                            datosformulario.tramite
                              ? datosformulario.tramite[0].motivo
                              : ''
                          "
                          label="MOTIVO"
                          name="motivo"
                          dense
                          filled
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col md="12" class="margen">
                        <v-textarea
                          :readonly="user.disabled"
                          :value="
                            datosformulario.tramite
                              ? datosformulario.tramite[0].deslinde
                              : ''
                          "
                          label="DESLINDE"
                          :rules="[(v) => !!v || 'Falta la deslinde']"
                          required
                          name="deslinde"
                          outlined
                          dense
                          filled
                        ></v-textarea>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <v-snackbar v-model="snackbarconfirm" :timeout="timeout" top>
              {{ msj }}

              <template v-slot:action="{ attrs }">
                <v-btn
                  color="blue"
                  text
                  v-bind="attrs"
                  @click="snackbarconfirm = false"
                >
                  cerrar
                </v-btn>
              </template>
            </v-snackbar>
          </v-container>
        </v-card-text>
        <v-card-actions class="custom-actions">
          <div>
            <v-btn dark color="error" class="mr-2" @click="dialog = false">
              Cancelar
            </v-btn>
            <v-btn
              dark
              color="claro"
              type="submit"
              id="btnenviarsolicitud"
              v-if="!user.disabled"
            >
              CONFIRMAR
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
export default {
  props: {
    user: Object,
    datostramite: Object,
  },
  data: () => ({
    dialog: false,
    validform: true,
    snackbarconfirm: false,
    timeout: 3000,
    msj: "",
    disabled: "",
    categorias: [],
    datosformulario: {},
  }),

  mounted() {
    // this.TraerCategorias();
    console.log("datosformulario", this.datosformulario);
  },

  watch: {
    // dialog(){
    //     if(this.DialogAdhesionBoleta){
    //     this.TraerDatosAdhesionBoleta();
    //     }
    // }
  },

  methods: {
    // TraerDatosAdhesionBoleta(){
    //     this.DialogAdhesionBoleta = true;
    // },
    VerFicha(item) {
      var obj = this;
      this.$ajax
        .get(`${this.$apitramites}escribanos/formulario/${item.cuenta}`)
        .then(function (response) {
          console.log("Ficha", response);
          obj.datosformulario = response.data;
          obj.dialog = true;
        })
        .catch(function (error) {
          obj.snackbarconfirm = true;
          obj.msj = error;
        });
    },

    TraerCategorias(item) {
      var obj = this;
      this.$ajax
        .get(`${this.$apitramites}funciones/listadoCategoriaEscribanos`)
        .then(function (response) {
          console.log("categorias", response);
          obj.categorias = response.data;
          obj.VerFicha(item);
        })
        .catch(function (error) {
          obj.snackbarconfirm = true;
          obj.msj = error;
        });
    },

    EnviarSolicitud(event) {
      if (this.$refs.formtramite.validate()) {
        // console.log("enviar formulario", event)
        var obj = this;
        this.$ajax
          .patch(
            `${this.$apitramites}escribanos/guardarFormulario`,
            Object.fromEntries(new FormData(event.target))
          )
          .then(function (response) {
            console.log("guardar formu", response);
            obj.$swal.fire({
              title: "¡Exito!",
              text: "Formulario cargado con éxito",
              icon: "success",
            });
            obj.$refs.formtramite.reset();

            setTimeout(function () {
              //   obj.$emit("cerrar");
              obj.dialog = false;
              obj.$emit("actualizarTabla");
            }, 1000);
          })
          .catch(function (error) {
            obj.snackbar = true;
            obj.msj = error;
          });
      }
    },

    vaciarExpanded() {
      // this.$emit("vaciarExpanded");
      this.dialog = false;
    },
  },
};
</script>
<style scoped>
.label-text {
  margin-bottom: 4px;
  /* font-weight: bold; */
  font-size: 15px;
  opacity: 0.85;
}
.theme--light.v-text-field--solo >>> .v-input__control > .v-input__slot {
  background-color: #afaaaa;
}
.theme--dark.v-text-field--solo >>> .v-input__control > .v-input__slot {
  background-color: #636363;
}
.margen {
  margin-top: -25px;
}
/* .formu { */
/* background: cornsilk; */
/* height: 100vh; */
/* } */
</style>
