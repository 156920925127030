import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import es from 'vuetify/es5/locale/es'

Vue.use(Vuetify);

export default new Vuetify({
  theme: {

    themes: {
      light: {
        claro: '#4f798f',
        // claro: '#628598',
        // claro: '#40A5DD',
        oscuro: '#162F3D',
        inmuebles: '#cf4242',
        comercios: '#0089ad',
        vehiculos: '#985396',
        cementerio: '#67b13d',
        escribanos: '#d09557',
        obrasprivadas: '#5760d0'
      },
      dark: {
        claro: '#4f798f',
        // claro: '#628598',
        // claro: '#40A5DD',
        oscuro: '#162F3D',
        inmueble: '#cf4242',
        comercios: '#0089ad',
        vehiculos: '#985396',
        cementerio: '#67b13d',
        escribanos: '#d09557',
        obrasprivadas: '#5760d0'
      },
    },
  },
    lang: {
        locales: { es },
        current: 'es',
      },
});

