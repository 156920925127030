<template>
  <v-app>
    <v-main style="padding: 0px 0px 0px 0px">
      <v-container fluid class="contenedor-todo">
        <v-row>
          <Menu></Menu>

          <v-container fluid class="content-col">
            <router-view />
          </v-container>
          <v-toolbar
            dense
            class="custom-toolbar justify-center"
            flat
            color="#cacaca"
          >
            <v-spacer></v-spacer>

            <v-spacer></v-spacer>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="ma-1 font-weight-bold"
                  plain
                  v-bind="attrs"
                  v-on="on"
                >
                  Contacto
                </v-btn>
              </template>
              <v-list dense>
                <v-subheader class="font-weight-bold">
                  <strong> Centro de Atención a Distancia(CAD): </strong>
                </v-subheader>
                <v-list-item
                  style="height: 40px"
                  v-for="contacto in contactos"
                  :key="contacto.title"
                >
                  <v-list-item-avatar>
                    <v-icon :color="contacto.color">{{ contacto.icon }}</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-title>{{ contacto.title }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-toolbar>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Menu from "@/components/menu.vue";

export default {
  name: "MainLayout",
  components: {
    Menu,
  },
  data: () => ({
    contactos: [
      // ... (datos de contactos)
    ],
  }),
  // ... (otros métodos y propiedades que no dependen de la ruta específica)
};
</script>
<style>
.v-data-table__expanded__content {
  background-color: #4f798f6e !important;
  height: 320px !important;
}
.bordecolor {
  border: 2px solid #4f798f !important;
}
.radiocard {
  border-radius: 30px !important;
}
.toolbardatos {
  height: 135px !important;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}
.color {
  color: #40a5dd !important;
}
.footertotal {
  display: flex;
  justify-content: flex-end;
  height: 50px;
  align-items: center;
}
.footerpago {
  display: flex;
  justify-content: flex-end;
  height: 50px;
  align-items: center;
}
.toolbarprincipal .v-toolbar__content {
  padding-right: 0px !important;
}
.margen {
  margin-top: -25px;
}
.blur-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(
    5px
  ); /* Ajusta el valor de desenfoque según lo necesites */
  z-index: 1;
}

.alert-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.alert {
  z-index: 2;
}

.error-alert {
  animation: shake 0.5s ease-in-out infinite alternate;
  background-color: #ffcccc !important;
  padding: 30px;
}
.ancho_tabla {
  width: 100%;
}
.tabla {
  border: 2px solid;
  border-color: #4f798f;
}
.v-btn {
  letter-spacing: 0;
}
.botones_pagar {
  width: 95px;
}
/* .letra-custom {
  font-family: inherit;
  font-style: italic;
} */
.v-data-table-header .v-simple-checkbox .v-icon {
  color: white; /* Cambia el color del ícono a blanco */
}
.custom-header-style .v-data-table-header {
  background-color: #4f798f; /* Cambia este valor al color que desees */
}
.disabled-row {
  background-color: #f5f5f5; /* Puedes cambiar el color para que parezca deshabilitada */
  color: #b0b0b0; /* Cambia el color del texto si lo deseas */
  pointer-events: none; /* Desactiva todos los eventos en la fila */
}

.contenedor-todo {
  background: #d9d9d9;
  overflow-y: auto;
}
.custom-toolbar {
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: 10;
}
.divtoolbar {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}
.dividers {
  width: 85%;
  border: 1px solid;
  border-color: #638699;
}

.content-col {
  padding-bottom: 60px;
  padding-left: 65px;
  min-height: 100vh;
  flex-grow: 1;
}

.calendar-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid;
  border-color: #4f798f;
}

.calendar-month {
  font-size: 18px;
  font-weight: medium;
  width: 100%;
  background-color: #4f798f;
  text-align: center;
}

.calendar-date {
  font-size: 24px;
  font-weight: bold;
  color: #4f798f;
}
.margenappbar {
  padding: 0px !important;
  margin-top: 64px !important;
}
.mayuscula {
  text-transform: uppercase;
}
.custom-actions {
  display: flex;
  justify-content: center;
  background-color: #cacaca;
}
</style>
<style lang="scss">
// #app {
//   // font-family: "Roboto", sans-serif !important;
//   // font-family: "Scout", sans-serif !important;
// }
html {
  overflow: auto;
}
#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
#rcorners2 {
  border-radius: 25px;
  border: 2px solid #73ad21;
  padding: 20px;
  width: 200px;
  height: 150px;
}
</style>
