<template>
   <v-app>
        <v-content :style="{ background : 'url('+this.fondo+') #000'}">
            <v-container fluid fill-height>
                <v-layout align-center justify-center>
                    <v-flex xs12 sm8 md4>
                        <v-card class="elevation-12">
                            <v-card-title>
                                Formulario de Registro
                            </v-card-title>
                            <v-divider></v-divider>
                            <v-card-text>
                                <v-col cols="12" class="d-flex justify-center" v-if="respuesta.estado==true"><h4>¡Cuenta verificada correctamente!</h4> <v-icon color="success">mdi-check-bold</v-icon></v-col>
                                <v-col cols="12" class="d-flex justify-center" v-if="respuesta.estado==false"><h4>¡Error al verificar la cuenta!</h4> <v-icon color="error">mdi-close-thick</v-icon></v-col>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="primary" small to="../">Volver</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-flex>
                </v-layout>
            </v-container>
        </v-content>
    </v-app>
</template>

<script>
export default {
    // name: 'Login',
    props: {
      source: String,
    },
   
    data(){
        return {
            fondo: "",
            images: {
            bgLogin: [
                    require('@/assets/fondos/fondo1.jpg'),
                    require('@/assets/fondos/fondo2.jpg'),
                    require('@/assets/fondos/fondo3.jpg'),
                    require('@/assets/fondos/fondo4.jpg'),
                    require('@/assets/fondos/fondo5.jpg'),
                    require('@/assets/fondos/fondo6.jpg'),
                    require('@/assets/fondos/fondo7.jpg')
                ]
            },
            respuesta:""
        }
   },

   mounted() {
        this.VerificarCuenta();
        this.elegirBg();
        this.cambiarFondo();
   },

   computed: {

   },


   methods: {
        elegirBg: function(){
            const rand = Math.floor(Math.random() * 7) + 1;
            this.fondo = this.images.bgLogin[rand-1];
        },
        cambiarFondo () {
            this.intBg = setInterval(() => {
                this.elegirBg();
            }, 15000)
        },
        VerificarCuenta(){
            var obj = this;
            var token = this.$route.params.token;
            this.$ajax
            .patch(`${this.$apitramites}users/verifyAccount/${token}`)
            .then(function(response) {
                console.log("Verificar Cuenta", response);
                obj.respuesta=response.data;
            })
            .catch(function(error) {
                obj.snackbar=true;
                obj.msj= error;
            });
        }
   },

};
</script>

<style>
main {
   background-size: cover!important;
   transition: all 0.5s ease-out;
   overflow: auto;
}
</style>