<template>
  <v-col cols="12" style="padding:20px;">
    <v-card style="border:1px solid" v-if="datosescribano.estado==0">
      <v-form
        @submit.prevent="EnviarSolicitud"
        ref="formsolicitudescribano"
        v-model="validformsolicitudescribano"
      >
        <v-toolbar dark color="claro" flat>
          <v-toolbar-title>Solicitud de Escribano</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="7" class="mt-4">
              <v-row>
                <v-col md="4" sm="6">
                  <v-text-field
                    name="cuit"
                    label="CUIT"
                    v-model="datosescribano.user[0].cuit"
                    readonly
                    placeholder=""
                    :rules="[(v) => !!v || 'Campo requerido']"
                    required
                    filled
                    outlined
                    dense
                  />
                </v-col>
                <v-col md="4" sm="6">
                  <v-text-field
                    name="apellido"
                    label="APELLIDO"
                    :value="(datosescribano.form.length!=0) ? datosescribano.form[0].apellido : ''"
                    placeholder=""
                    :rules="[(v) => !!v || 'Campo requerido']"
                    required
                    filled
                    outlined
                    dense
                  />
                </v-col>
                <v-col md="4" sm="6">
                  <v-text-field
                    name="nombre"
                    :value="(datosescribano.form.length!=0) ? datosescribano.form[0].nombre : ''"
                    label="NOMBRE"
                    placeholder=""
                    :rules="[(v) => !!v || 'Campo requerido']"
                    required
                    filled
                    outlined
                    dense
                  />
                </v-col>
                <v-col md="3" sm="6">
                  <v-text-field
                    name="provincia"
                    label="PROVINCIA"
                    :value="(datosescribano.form.length!=0) ? datosescribano.form[0].provincia : ''"
                    placeholder=""
                    :rules="[(v) => !!v || 'Campo requerido']"
                    required
                    filled
                    outlined
                    dense
                    class="margen"
                  />
                </v-col>
                <v-col md="3" sm="6">
                  <v-text-field
                    name="partido"
                    label="PARTIDO"
                    :value="(datosescribano.form.length!=0) ? datosescribano.form[0].partido : ''"
                    placeholder=""
                    :rules="[(v) => !!v || 'Campo requerido']"
                    required
                    filled
                    outlined
                    dense
                    class="margen"
                  />
                </v-col>
                <v-col md="3" sm="6">
                  <v-text-field
                    name="localidad"
                    label="LOCALIDAD"
                    :value="(datosescribano.form.length!=0) ? datosescribano.form[0].localidad : ''"
                    placeholder=""
                    :rules="[(v) => !!v || 'Campo requerido']"
                    required
                    filled
                    outlined
                    dense
                    class="margen"
                  />
                </v-col>
                <v-col md="3" sm="6">
                  <v-text-field
                    name="cod_postal"
                    label="C.P"
                    :value="(datosescribano.form.length!=0) ? datosescribano.form[0].codigo_postal : ''"
                    placeholder=""
                    :rules="[(v) => !!v || 'Campo requerido']"
                    required
                    filled
                    outlined
                    dense
                    class="margen"
                  />
                </v-col>
                <v-col md="6" sm="6">
                  <v-text-field
                    name="calle"
                    label="CALLE"
                    :value="(datosescribano.form.length!=0) ? datosescribano.form[0].calle : ''"
                    placeholder=""
                    :rules="[(v) => !!v || 'Campo requerido']"
                    required
                    filled
                    outlined
                    dense
                    class="margen"
                  />
                </v-col>
                <v-col md="2" sm="6">
                  <v-text-field
                    name="num_calle"
                    label="N°"
                    :value="(datosescribano.form.length!=0) ? datosescribano.form[0].num_calle : ''"
                    placeholder=""
                    filled
                    outlined
                    dense
                    class="margen"
                  />
                </v-col>
                <v-col md="2" sm="6">
                  <v-text-field
                    name="piso"
                    label="PISO"
                    :value="(datosescribano.form.length!=0) ? datosescribano.form[0].piso : ''"
                    placeholder=""
                    filled
                    outlined
                    dense
                    class="margen"
                  />
                </v-col>
                <v-col md="2" sm="6">
                  <v-text-field
                    name="depto"
                    label="DEPTO"
                    :value="(datosescribano.form.length!=0) ? datosescribano.form[0].depto : ''"
                    placeholder=""
                    filled
                    outlined
                    dense
                    class="margen"
                  />
                </v-col>
                <v-col md="4" sm="6">
                  <v-text-field
                    name="mail"
                    label="MAIL"
                    v-model="datosescribano.user[0].mail"
                    readonly
                    placeholder=""
                    :rules="[(v) => !!v || 'Campo requerido']"
                    required
                    filled
                    outlined
                    dense
                    class="margen"
                  />
                </v-col>
                <v-col md="4" sm="6">
                  <v-text-field
                    name="telefono"
                    label="TELÉFONO"
                    :value="(datosescribano.form.length!=0) ? datosescribano.form[0].telefono : ''"
                    placeholder=""
                    :rules="[(v) => !!v || 'Campo requerido']"
                    required
                    filled
                    outlined
                    dense
                    class="margen"
                  />
                </v-col>
                <v-col md="4" sm="6">
                  <v-text-field
                    name="num_registro"
                    label="N° REGISTRO"
                    :value="(datosescribano.form.length!=0) ? datosescribano.form[0].nro_reg : ''"
                    placeholder=""
                    :rules="[(v) => !!v || 'Campo requerido']"
                    required
                    filled
                    outlined
                    dense
                    class="margen"
                  />
                </v-col>
                <v-col md="6" sm="12">
                  <v-text-field
                    name="matricula"
                    label="MATRÍCULA"
                    :value="(datosescribano.form.length!=0) ? datosescribano.form[0].matricula : ''"
                    placeholder=""
                    :rules="[(v) => !!v || 'Campo requerido']"
                    required
                    filled
                    outlined
                    dense
                    class="margen"
                  />
                </v-col>
                <v-col md="6" sm="12">
                  <v-text-field
                    name="escribania"
                    label="ESCRIBANÍA"
                    :value="(datosescribano.form.length!=0) ? datosescribano.form[0].escribania : ''"
                    placeholder=""
                    :rules="[(v) => !!v || 'Campo requerido']"
                    required
                    filled
                    outlined
                    dense
                    class="margen"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="5" class="margen">
              <v-radio-group name="titular_adscripto" class="mt-5" row required :value="(datosescribano.form.length!=0) ? datosescribano.form[0].tit_ads : ''">
                <b>TIPO:</b>
                <v-radio
                  style="margin-left:10px;"
                  label="Titular"
                  :value="0"
                ></v-radio>
                <v-radio label="Adscripto" :value="1"></v-radio>
              </v-radio-group>
              <v-row>
                <v-col md="6" sm="12">
                  <v-card outlined class="borde cardarchivo" height="auto">
                    <v-card-title>
                      Sello
                    </v-card-title>
                    <v-card-text class="cardarchivo">
                      <input
                        type="file"
                        ref="fileInput"
                        style="display: none"
                        @change="handleFileUpload"
                        accept="image/*"
                        name="sello"
                      />
                      <v-img
                        v-if="imageUrl"
                        :src="imageUrl"
                        max-height="150"
                        max-width="150"
                        contain
                        @click="triggerFileInput"
                      ></v-img>
                      <v-icon v-else @click="triggerFileInput" style="font-size: 100px; color: #ccc;">mdi-cloud-upload</v-icon>
                      <v-btn x-small color="error" class="mt-4" @click="imageUrl=''">Borrar archivo</v-btn>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col md="6" sm="12">
                  <v-card outlined class="borde cardarchivo" height="auto">
                    <v-card-title>
                      Firma
                    </v-card-title>
                    <v-card-text class="cardarchivo">
                      <input
                        type="file"
                        ref="fileInput2"
                        style="display: none"
                        @change="handleFileUpload2"
                        accept="image/*"
                        name="firma"
                      />
                      <v-img
                        v-if="imageUrl2"
                        :src="imageUrl2"
                        max-height="150"
                        max-width="150"
                        contain
                        @click="triggerFileInput2"
                      ></v-img>
                      <v-icon v-else @click="triggerFileInput2" style="font-size: 100px; color: #ccc;">mdi-cloud-upload</v-icon>
                      <!-- <a style="display: block;text-align: center;color:black;"
                        ><span>Borrar Archivo</span></a
                      > -->
                      <v-btn x-small color="error" class="mt-4" @click="imageUrl2=''">Borrar archivo</v-btn>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn dark color="claro" type="submit" id="btnenviarsolicitud">
            Enviar Solicitud
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
    <v-row justify="center" :class="(datosescribano.estado==1 || datosescribano.estado==3)?'full-height d-flex align-center':''">
      <v-col cols="8" v-if="datosescribano.estado==1">
        <v-card class="solicitudes">
            <v-row align="center">
                <v-col cols="10">
                    <v-card-title class="text-h4 font-weight-bold grey--text text--darken-2">{{datosescribano.titulo}}</v-card-title>
                    <v-card-subtitle class="text-h6 grey--text">{{datosescribano.msj}}</v-card-subtitle>
                </v-col>
                <v-col cols="2">
                    <v-icon class="icono" color="grey">mdi-timer-sand-complete</v-icon>
                </v-col>
            </v-row>
        </v-card>
      </v-col>
      <v-col cols="8" v-if="datosescribano.estado==3">
        <v-card class="solicitudes">
            <v-row align="center">
                <v-col cols="10">
                    <v-card-title class="text-h4 font-weight-bold grey--text text--darken-2">{{datosescribano.titulo}}</v-card-title>
                    <v-card-subtitle class="text-h6 grey--text text--darken-2">{{datosescribano.msj}}</v-card-subtitle>

                        <v-divider></v-divider>

                    <v-col cols="12" class="mx-auto grey--text text--darken-2">Puede editar el formulario para modificar los datos incorrectos y volver a realizar la inscripción.</v-col>
                    <v-btn
                        color="#00B0FF"
                        dark
                        small
                        class="ml-3"
                        @click="datosescribano.estado=0"
                    >
                        EDITAR FORMULARIO
                    </v-btn>
                </v-col>
                <v-col cols="2">
                    <v-icon class="icono" color="grey">mdi-exclamation-thick</v-icon>
                </v-col>
            </v-row>

        </v-card>
      </v-col>
    </v-row>
  </v-col>

</template>

<script>
export default {
    props: {
      datosescribano: Object
    },
  data: () => ({
    selectedFile: null,
    selectedFile2: null,
    imageUrl: '',
    imageUrl2: '',
    validformsolicitudescribano: true,
    file: null,
    // imageUrl: "https://nfs.lacosta.gob.ar/backend/pruebita/",
    controleditar:0,
    user: {checkescri: {}},
    imagen:"",
  }),

  components: {},

  computed: {
    // imageUrl() {
    //   return URL.createObjectURL(this.selectedFile);
    // },
    // imageUrl2() {
    //   return URL.createObjectURL(this.selectedFile2);
    // },
  },

  watch: {},

  mounted() {
    // console.log("datos", this.datosescribano)
    // var obj = this;
    // obj.$on('datos-recibidos', function(datos) {
    //   console.log("entra", datos)
    //   obj.datosescribano = datos;
    // });
  },

  methods: {
    // LLenarFormu(){
    //     this.datosescribano=this.user;
    //     console.log("datos escri", this.datosescribano)
    // },

    handleFileUpload(event) {
      const file = event.target.files[0];
      if (file && file.type.startsWith('image/')) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.imageUrl = e.target.result;
        };
        reader.readAsDataURL(event.target.files[0]);
      }else{
        this.$swal.fire({
            title: "Atención",
            text: "Seleccione otro tipo de imagen",
            icon: "info"
          });
      }
    },
    
    triggerFileInput() {
      this.$refs.fileInput.click();
    },

    handleFileUpload2(event) {
      const file = event.target.files[0];
      if (file && file.type.startsWith('image/')) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.imageUrl2 = e.target.result;
        };
        reader.readAsDataURL(event.target.files[0]);
      }else{
        this.$swal.fire({
            title: "Atención",
            text: "Seleccione otro tipo de imagen",
            icon: "info"
          });
      }
    },

    triggerFileInput2() {
      this.$refs.fileInput2.click();
    },

    EnviarSolicitud(event) {
      const formData = new FormData(event.target);
      console.log("datos", formData);
      // formData.append("sello", this.$refs.fileInput.files[0])
      // formData.append("firma", this.$refs.fileInput2.files[0])
      // console.log("datos 2", Object.fromEntries(new FormData(event.target)));
      if (this.$refs.formsolicitudescribano.validate()) {
        // alert("si bebe");
        var obj = this;
        this.$ajax
        .post(`${this.$apitramites}escribanos/enviarSolicitud`, formData , {headers:{"Content-Type":"multipart/form-data"}})
        .then(function(response) {
            console.log("Enviar solicitud", response);
            if(response){
              obj.$swal.fire({
                title: "¡Exito!",
                text: "Solicitud enviada con exito",
                icon: "success"
              });
              obj.$refs.formsolicitudescribano.reset();
              obj.$emit("check");
            }  
        })
        .catch(function(error) {
          obj.$swal.fire({
            title: "Error",
            text: error.response.data.mensaje, 
            icon: "error"
          });
        });
      }
    }
  },
};
</script>

<style scoped>
.borde {
  border: 2px solid #ccc;
  border-radius: 8px; /* Opcional: para esquinas redondeadas */
}
.icono {
  display: flex;
  align-items: center;
  font-size: 85px;
}
.cardarchivo{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.margen{
    margin-top: -25px;
}
.full-height{
  height: 100vh;
}
.solicitudes{
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid; 
  background-color: #ccc; 
  padding: 10px;
}
</style>
