var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-col',{staticStyle:{"padding":"30px"},attrs:{"cols":"12"}},[(_vm.datosprofesional.estado == 0)?_c('v-card',{staticClass:"mt-10",staticStyle:{"border":"1px solid","margin-bottom":"20px"}},[_c('v-form',{ref:"formsolicitudobra",on:{"submit":function($event){$event.preventDefault();return _vm.EnviarSolicitud.apply(null, arguments)}},model:{value:(_vm.validformsolicitudobra),callback:function ($$v) {_vm.validformsolicitudobra=$$v},expression:"validformsolicitudobra"}},[_c('v-toolbar',{attrs:{"dark":"","color":"claro","flat":""}},[_c('v-toolbar-title',[_vm._v("Solicitud de registro de profesionales")])],1),_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"mt-4",attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{attrs:{"md":"3","sm":"6"}},[_c('v-text-field',{attrs:{"name":"cuit","label":"CUIT","readonly":"","placeholder":"","rules":[(v) => !!v || 'requerido'],"required":"","filled":"","outlined":"","dense":""},model:{value:(_vm.datosprofesional.user[0].cuit),callback:function ($$v) {_vm.$set(_vm.datosprofesional.user[0], "cuit", $$v)},expression:"datosprofesional.user[0].cuit"}})],1),_c('v-col',{attrs:{"md":"3","sm":"6"}},[_c('v-text-field',{attrs:{"name":"apellido","label":"APELLIDO","value":_vm.datosprofesional.form.length != 0
                      ? _vm.datosprofesional.form[0].apellido
                      : '',"placeholder":"","rules":[(v) => !!v || 'requerido'],"required":"","filled":"","outlined":"","dense":""}})],1),_c('v-col',{attrs:{"md":"3","sm":"6"}},[_c('v-text-field',{attrs:{"name":"nombre","value":_vm.datosprofesional.form.length != 0
                      ? _vm.datosprofesional.form[0].nombre
                      : '',"label":"NOMBRE","placeholder":"","rules":[(v) => !!v || 'requerido'],"required":"","filled":"","outlined":"","dense":""}})],1),_c('v-col',{attrs:{"md":"3","sm":"6"}},[_c('v-text-field',{attrs:{"name":"provincia","label":"PROVINCIA","value":_vm.datosprofesional.form.length != 0
                      ? _vm.datosprofesional.form[0].provincia
                      : '',"placeholder":"","rules":[(v) => !!v || 'requerido'],"required":"","filled":"","outlined":"","dense":""}})],1),_c('v-col',{attrs:{"cols":"5"}},[_c('v-row',[_c('v-col',{attrs:{"md":"9","sm":"6"}},[_c('v-text-field',{staticClass:"margen",attrs:{"name":"localidad","label":"LOCALIDAD","value":_vm.datosprofesional.form.length != 0
                          ? _vm.datosprofesional.form[0].localidad
                          : '',"placeholder":"","rules":[(v) => !!v || 'requerido'],"required":"","filled":"","outlined":"","dense":""}})],1),_c('v-col',{attrs:{"md":"3","sm":"6"}},[_c('v-text-field',{staticClass:"margen",attrs:{"name":"cod_postal","label":"C.P","value":_vm.datosprofesional.form.length != 0
                          ? _vm.datosprofesional.form[0].codigo_postal
                          : '',"placeholder":"","rules":[(v) => !!v || 'requerido'],"required":"","filled":"","outlined":"","dense":""}})],1)],1)],1),_c('v-col',{attrs:{"cols":"7"}},[_c('v-row',[_c('v-col',{attrs:{"md":"6","sm":"6"}},[_c('v-text-field',{staticClass:"margen",attrs:{"name":"calle","label":"CALLE","value":_vm.datosprofesional.form.length != 0
                          ? _vm.datosprofesional.form[0].calle
                          : '',"placeholder":"","rules":[(v) => !!v || 'requerido'],"required":"","filled":"","outlined":"","dense":""}})],1),_c('v-col',{attrs:{"md":"2","sm":"6"}},[_c('v-text-field',{staticClass:"margen",attrs:{"name":"num_calle","label":"N°","value":_vm.datosprofesional.form.length != 0
                          ? _vm.datosprofesional.form[0].num_calle
                          : '',"placeholder":"","filled":"","outlined":"","dense":""}})],1),_c('v-col',{attrs:{"md":"2","sm":"6"}},[_c('v-text-field',{staticClass:"margen",attrs:{"name":"piso","label":"PISO","value":_vm.datosprofesional.form.length != 0
                          ? _vm.datosprofesional.form[0].piso
                          : '',"placeholder":"","filled":"","outlined":"","dense":""}})],1),_c('v-col',{attrs:{"md":"2","sm":"6"}},[_c('v-text-field',{staticClass:"margen",attrs:{"name":"depto","label":"DEPTO","value":_vm.datosprofesional.form.length != 0
                          ? _vm.datosprofesional.form[0].depto
                          : '',"placeholder":"","filled":"","outlined":"","dense":""}})],1)],1)],1),_c('v-col',{attrs:{"md":"3","sm":"6"}},[_c('v-text-field',{staticClass:"margen",attrs:{"name":"mail","label":"MAIL","readonly":"","placeholder":"","rules":[(v) => !!v || 'requerido'],"required":"","filled":"","outlined":"","dense":""},model:{value:(_vm.datosprofesional.user[0].mail),callback:function ($$v) {_vm.$set(_vm.datosprofesional.user[0], "mail", $$v)},expression:"datosprofesional.user[0].mail"}})],1),_c('v-col',{attrs:{"md":"3","sm":"6"}},[_c('v-text-field',{staticClass:"margen",attrs:{"name":"telefono","label":"TELÉFONO","value":_vm.datosprofesional.form.length != 0
                      ? _vm.datosprofesional.form[0].telefono
                      : '',"placeholder":"","rules":[(v) => !!v || 'requerido'],"required":"","filled":"","outlined":"","dense":""}})],1),_c('v-col',{attrs:{"md":"3","sm":"6"}},[_c('v-text-field',{staticClass:"margen",attrs:{"name":"expedida","label":"Expedida por","value":_vm.datosprofesional.form.length != 0
                      ? _vm.datosprofesional.form[0].expedida
                      : '',"placeholder":"","rules":[(v) => !!v || 'requerido'],"required":"","filled":"","outlined":"","dense":""}})],1),_c('v-col',{attrs:{"md":"3","sm":"6"}},[_c('v-autocomplete',{staticClass:"margen",attrs:{"items":_vm.tit_habilitantes,"item-text":"t_deta","item-value":"t_cod","placeholder":"Seleccione un titulo habilitante","rules":[(v) => !!v || 'requerido'],"required":"","outlined":"","filled":"","dense":"","label":"Tit.Habilitante"},model:{value:(_vm.combo_titular_habilitante),callback:function ($$v) {_vm.combo_titular_habilitante=$$v},expression:"combo_titular_habilitante"}})],1),_c('v-col',{attrs:{"md":"6","sm":"12"}},[_c('v-text-field',{staticClass:"margen",attrs:{"name":"matricula_profesional","label":"MATRÍCULA PROFESIONAL","value":_vm.datosprofesional.form.length != 0
                      ? _vm.datosprofesional.form[0].matricula
                      : '',"placeholder":"","rules":[(v) => !!v || 'requerido'],"required":"","filled":"","outlined":"","dense":""}})],1),_c('v-col',{attrs:{"md":"6","sm":"12"}},[_c('v-text-field',{staticClass:"margen",attrs:{"name":"matricula_municipal","label":"MATRICULA MUNICIPAL","value":_vm.datosprofesional.form.length != 0
                      ? _vm.datosprofesional.form[0].escribania
                      : '',"placeholder":"","filled":"","outlined":"","dense":""}})],1)],1)],1),_c('v-col',{staticStyle:{"background-color":"#b3e5fc"},attrs:{"md":"6"}},[_c('span',{staticStyle:{"color":"#f00"}},[_vm._v("*")]),_c('strong',[_vm._v("En caso de no contar con Matricula Municipal deberá adjuntar la siguiente documentación en un solo archivo PDF:")]),_c('ul',[_c('li',[_vm._v("Foto Carnet 3x3cm")]),_c('li',[_vm._v("Titulo")]),_c('li',[_vm._v("DNI")]),_c('li',[_vm._v("Último pago de monotributo")]),_c('li',[_vm._v("Inscripción en Ingresos Brutos")]),_c('li',[_vm._v("Nº de Legajo Caja Previsional")]),_c('li',[_vm._v("Matrícula Profesional")]),_c('li',[_vm._v("Último pago de Matrícula")])])]),_c('v-col',{attrs:{"md":"6"}},[_c('v-file-input',{attrs:{"chips":"","filled":"","show-size":"","rules":[_vm.fileSizeRule],"name":"adjunto","label":"Archivo"},model:{value:(_vm.files),callback:function ($$v) {_vm.files=$$v},expression:"files"}})],1)],1)],1),_c('v-card-actions',{staticClass:"mt-10 justify-center"},[_c('v-btn',{staticStyle:{"margin-bottom":"10px"},attrs:{"dark":"","color":"claro","type":"submit","id":"btnenviarsolicitud"}},[_vm._v(" Enviar Solicitud ")])],1)],1)],1):_vm._e(),_c('v-row',{class:_vm.datosprofesional.estado == 1 || _vm.datosprofesional.estado == 3
        ? 'full-height d-flex align-center'
        : '',attrs:{"justify":"center"}},[(_vm.datosprofesional.estado == 1)?_c('v-col',{attrs:{"cols":"8"}},[_c('v-card',{staticClass:"solicitudes"},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"10"}},[_c('v-card-title',{staticClass:"text-h4 font-weight-bold grey--text text--darken-2"},[_vm._v(_vm._s(_vm.datosprofesional.titulo))]),_c('v-card-subtitle',{staticClass:"text-h6 grey--text"},[_vm._v(_vm._s(_vm.datosprofesional.msj))])],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-icon',{staticClass:"icono",attrs:{"color":"grey"}},[_vm._v("mdi-timer-sand-complete")])],1)],1)],1)],1):_vm._e(),(_vm.datosprofesional.estado == 3)?_c('v-col',{attrs:{"cols":"8"}},[_c('v-card',{staticClass:"solicitudes"},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"10"}},[_c('v-card-title',{staticClass:"text-h4 font-weight-bold grey--text text--darken-2"},[_vm._v(_vm._s(_vm.datosprofesional.titulo))]),_c('v-card-subtitle',{staticClass:"text-h6 grey--text text--darken-2"},[_vm._v(_vm._s(_vm.datosprofesional.msj))]),_c('v-divider'),_c('v-col',{staticClass:"mx-auto grey--text text--darken-2",attrs:{"cols":"12"}},[_vm._v("Puede editar el formulario para modificar los datos incorrectos y volver a realizar la inscripción.")])],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-icon',{staticClass:"icono",attrs:{"color":"grey"}},[_vm._v("mdi-exclamation-thick")])],1)],1),_c('v-card-actions',{staticClass:"justify-center"},[_c('v-btn',{staticClass:"ml-3",attrs:{"color":"claro","dark":"","small":""},on:{"click":function($event){_vm.LlenarTitulos();
              _vm.datosprofesional.estado = 0;}}},[_vm._v(" EDITAR FORMULARIO ")])],1)],1)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }