<template>
<v-app>
  <v-container fluid fill-height class="ma-0">
      <v-layout justify-center align-center>
        <v-flex text-center>
          <v-img :src="backgroundImage" class="fondo" style="width: 100%; height: 100%; object-fit: cover;"></v-img>
          <!-- <v-card class="pa-10 ma-4 mx-0 my-0" flat>
            <v-card-subtitle class="headline">El pago se realizo con exito!!!</v-card-subtitle>
          </v-card> -->
        </v-flex>
      </v-layout>
    </v-container>
</v-app>
    
</template>

<script>
// import { bus } from '@/main';
export default {
  data() {
    return {
        backgroundImage: require('../assets/fondos/fondo_tramites.jpg'),
        formulario:{},
        validform:true,
        numeroAleatorio:""
    };
  },
  mounted(){
    // console.log("hola", this.$route.query)
    this.formulario=this.$route.query;
    this.numeroAleatorio= Math.floor(Math.random() * 1000) + 1;
    console.log("aleatorio", this.numeroAleatorio)
    console.log("formulario", this.$route);
    this.$swal.fire({
        title: "Pago realizado con exito",
        text: "N° de recibo:"+ this.$route.query.recibos,
        icon: "success",
        // showCancelButton: true,
        // reverseButtons: true,
        confirmButtonColor: "#3085d6",
        // cancelButtonColor: "#d33",
        // cancelButtonText: "No, cancelar!",
        confirmButtonText: "Volver",
      }).then((result) => {
        if (result.isConfirmed) {
          this.CerrarPagina()
        }
    });
  },
  methods:{
    // CargarFormulario(datos){
    //   this.formulario=datos;
    // },
    CerrarPagina(){
        console.log("a")
      // this.$router.go(-1);
      this.$router.push("/principal");
    //   window.close();
    },
  }
};
</script>

<style scoped>
.fondo{
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    filter: blur(5px);
    z-index: -999px;
}
</style>