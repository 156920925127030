<template>
  <v-dialog v-model="dialog" persistent max-width="600" class="zindex-dialog">
    <v-card>
      <!-- <v-app-bar color="oscuro" fixed dark>
        <v-toolbar-title>Pagar deudas de Pepito</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="dialog = false"><v-icon>mdi-close</v-icon></v-btn>
      </v-app-bar> -->
      <v-card-title class="headline">
        <v-row justify="center" style="margin-top: 5px">
          <b>Adhesion a boleta electrónica</b>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-form
          @submit.prevent="EnviarSolicitud"
          ref="formadhesionboleta"
          v-model="validformadhesionboleta"
        >
          <v-card-text>
            <v-container>
              <v-text-field
                name="mail"
                label="Mail"
                placeholder=""
                :rules="[(v) => !!v || 'Campo requerido']"
                required
                filled
                outlined
                dense
                class="mt-4"
              />
              <v-row>
                <v-col cols="12">
                  <p style="color: #2196f3">
                    Autorizo a la Municipalidad de La Costa a enviar via E-Mail
                    las boletas de la Tasa de Servicios Generales. Ademas
                    quedara sin efecto el envio de la boleta por correo
                    convencional. La presente solicitud queda sujeta a
                    confirmacion.
                  </p>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions class="justify-center">
            <v-btn dark color="error" class="mr-2" @click="dialog = false">
              cancelar
            </v-btn>
            <v-btn dark color="claro" type="submit" id="btnenviarsolicitud">
              Enviar Solicitud
            </v-btn>
          </v-card-actions>
          <v-snackbar v-model="snackbarconfirm" :timeout="timeout" top>
            {{ msj }}

            <template v-slot:action="{ attrs }">
              <v-btn
                color="blue"
                text
                v-bind="attrs"
                @click="snackbarconfirm = false"
              >
                Cerrar
              </v-btn>
            </template>
          </v-snackbar>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    user: Object,
  },
  data: () => ({
    dialog: false,
    validformadhesionboleta: true,
    snackbarconfirm: false,
    timeout: 3000,
    msj: "",
  }),

  watch: {},

  //   created() {
  //     this.initilize();
  //   },

  methods: {
    initialize() {
      this.dialog = true;
    },

    EnviarSolicitud(event) {
      console.log("asdas", Object.fromEntries(new FormData(event.target)).mail);
      if (this.$refs.formadhesionboleta.validate()) {
        var obj = this;
        this.$ajax
          .post(`${this.$apitramites}inmuebles/reciboElectronico`, {
            cuenta: this.user.datosinmueble.cuenta,
            mail: Object.fromEntries(new FormData(event.target)).mail,
          })
          .then(function (response) {
            console.log("Enviar solicitud boleta electronica", response);
            obj.$swal.fire({
              title: "¡Exito!",
              text: "Solicitud enviada",
              icon: "success",
            });
            obj.$refs.formadhesionboleta.reset();
            obj.vaciarExpanded();
          })
          .catch(function (error) {
            obj.snackbar = true;
            obj.msj = error;
          });
      }
    },

    vaciarExpanded() {
      // this.$emit("vaciarExpanded");
      this.dialog = false;
    },
  },
};
</script>
<style scoped>
.label-text {
  margin-bottom: 4px;
  /* font-weight: bold; */
  font-size: 15px;
  opacity: 0.85;
}
.theme--light.v-text-field--solo >>> .v-input__control > .v-input__slot {
  background-color: #afaaaa;
}
.theme--dark.v-text-field--solo >>> .v-input__control > .v-input__slot {
  background-color: #636363;
}
</style>
