import Vue from 'vue'
import VueRouter from 'vue-router'
import Inmuebles from '../views/Inmuebles/Inmuebles.vue'
import Cementerio from '../views/Cementerio/Cementerio.vue'
import Comercios from '../views/Comercios/Comercios.vue'
import Escribanos from '../views/Escribanos/Escribanos.vue'
import Obrasprivadas from '../views/ObrasPrivadas/Obrasprivadas.vue'
import Vehiculos from '../views/Vehiculos/Vehiculos.vue'
import Proveedores from '../views/Proveedores/Proveedores.vue'
import Login from '../views/Login.vue'
import Formularioregistro from '../views/Formulario_registro.vue'
import Pagina_Redireccion from '../components/Pagina_redireccion.vue'
import Principal from '@/Principal.vue'
import Verificar from '../views/Verificar.vue'
import Success from '../components/success.vue'
import Cancel from '../components/cancel.vue'
// import prueba from '../prueba.vue'
import MainLayout from '@/layouts/MainLayout.vue'

Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/',
  //   name: 'prueba',
  //   component: prueba,
  // },
  {
    path: '/',
    name: 'Login',
    component: Login,
  },
  {
    path: '/tramites',
    name: 'TramitesLogin',
    component: Login,
  },
  {
    path: '/Verificar/:token',
    name: 'Verificar',
    component: Verificar,
  },
  {
    path: '/Formulario_registro',
    name: 'Formulario_registro',
    component: Formularioregistro,
  },
  {
    path: '/Pagina_redireccion',
    name: 'Pagina_redireccion',
    component: Pagina_Redireccion,
  },
  {
    path: '/success',
    name: 'success',
    component: Success,
  },
  {
    path: '/Cancel',
    name: 'Cancel',
    component: Cancel,
  },
  {
    path: '/',
    component: MainLayout,
    children: [
      {
        path: 'principal',
        name: 'Principal',
        component: Principal
      },
      {
        path: '/inmueble',
        name: 'Inmueble',
        component: Inmuebles,
      },
      {
        path: '/comercio',
        name: 'Comercio',
        component: Comercios,
      },
      {
        path: '/vehiculos',
        name: 'Vehiculos',
        component: Vehiculos,
      },
      {
        path: '/cementerio',
        name: 'Cementerio',
        component: Cementerio,
      },
      {
        path: '/escribanos',
        name: 'Escribanos',
        component: Escribanos,
      },
      {
        path: '/obrasprivadas',
        name: 'ObrasPrivadas',
        component: Obrasprivadas,
      },
      {
        path: '/Proveedores',
        name: 'Proveedores',
        component: Proveedores,
      },
    ]
  },  
  


]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
